import React from 'react';
import { Button } from 'react-bootstrap';
import {
  FaCalendarAlt,
  FaCommentDots,
  FaFilter,
  FaDatabase,
  FaChartLine,
  FaCog,
  FaSignOutAlt,
  FaUserSlash,
  FaRegCalendarAlt,
  FaRegCalendarMinus,
  FaCommentAlt,
  FaComments,
  FaRegComment,
  FaRegComments,
  FaRegChartBar
} from 'react-icons/fa';
import '../../css/sidebar.css';
import handleLogout from '../../functions/functions';
import { useNavigate } from 'react-router-dom';

interface SidebarBarProps {
  redirect: (route: string) => void;
}

const Sidebar: React.FC<SidebarBarProps> = ({ redirect }) => {
  return (
    <div className="sidebar-bar ">
      <div className="row">
        <Button variant="light" onClick={() => redirect('myschedule')}>
          <FaRegCalendarMinus className="icon" />
          Mi Agenda
        </Button>
      </div>
      <div className="row ">
        <Button variant="light" onClick={() => redirect('myconversations')}>
          <FaRegComments className="icon" />
          Mis Conversaciones
        </Button>
      </div>
      <div className="row">
        {/* Si necesitas el embudo (Embudos), usa FaFilter */}
        <Button variant="light" onClick={() => redirect('funnels')}>
          <FaFilter className="icon" />
          Embudos
        </Button>
      </div>
      <div className="row">
        <Button variant="light" onClick={() => redirect('myexpenses')}>
          <FaDatabase className="icon" />
          Consumo
        </Button>
      </div>
      <div className="row ">
        <Button variant="light" onClick={() => redirect('statistics')}>
          <FaRegChartBar className="icon" />
          Estadísticas
        </Button>
      </div>
      <div className="row">
        <Button variant="light" onClick={() => redirect('settings')}>
          <FaCog className="icon" />
          Configuración
        </Button>
      </div>
      {/* <div className="row mb-3">
        <Button className="text-danger" variant="light" onClick={handleLogout}>
          <FaSignOutAlt className="icon" />
          Cerrar Sesión
        </Button>
      </div> */}
      {/* Empuja este botón al fondo usando .mt-auto */}
      <hr style={{ height: '5px' }} />
      <div className="mt-auto row mb-3">
        {/* <Button className="text-danger" variant="light" onClick={() => redirect('delete-account')}>
          <FaUserSlash className="icon" />
          Desactivar cuenta
        </Button> */}
        <Button className="text-danger" variant="light" onClick={handleLogout}>
          <FaSignOutAlt className="icon" />
          Cerrar Sesión
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;