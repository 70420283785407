import React, { useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import SupportChatModal from '../Home/SupportChatModal';
import Sidebar from '../Sidebar/Sidebar';
import TopBar from '../../TopBar';
import { useNavigate } from 'react-router-dom';
import { fetchBusinessStatus } from '../../functions/functions';
import { Assistant, Channel } from '../Conversations/RightColumn';
import axios from 'axios';
import config from '../../config/config';

// Bootstrap components
import { Form, Container, Button, Spinner } from 'react-bootstrap';
import NewFunnelModal from './NewFunnelModal';

// Import your provided Kanban components
import KanbanList from './KanbanList';
import KanbanCard from './KanbanCard';
import KanbanHeader from './KanbanHeader';
import EditFunnelModal from './EditFunnelModal';
import ConversationChatModal from '../Modals/ConversationChatModal';

// Import react-beautiful-dnd components
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export interface Funnel {
  _id?: string;
  funnel_name: string;
  description: string;
  business_id: string;
  assistants: string[];
  categories: string[];
  icon?: string;
  schema_version?: string;
  // Appended property from backend route:
  conversations?: any[];
  columnOrder?: string[];
}

const Funnels: React.FC = () => {
  const [show, setShow] = useState(false);
  const gojimx_payment_type = localStorage.getItem('gojimx_payment_type');
  const gojimx_businessId = localStorage.getItem('gojimx_businessId');
  const gojimx_token = localStorage.getItem('gojimx_token');

  const navigate = useNavigate();
  const [supportChatOpen, setSupportChatOpen] = useState(false);
  const [predefinedQuestion, setPredefinedQuestion] = useState<string>();
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [categoryIcons, setCategoryIcons] = useState<{ [key: string]: any }>({});

  // State for controlling the modals
  const [showNewFunnelModal, setShowNewFunnelModal] = useState(false);
  const [showEditFunnelModal, setShowEditFunnelModal] = useState(false);

  // Loading states
  const [isLoadingFunnels, setIsLoadingFunnels] = useState(false);
  const [isCreatingFunnel, setIsCreatingFunnel] = useState(false);
  const [isUpdatingFunnel, setIsUpdatingFunnel] = useState(false);
  const [isDeletingFunnel, setIsDeletingFunnel] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  // Fields for the funnel form (used for both New and Edit)
  const [selectedIcon, setSelectedIcon] = useState<string>('');
  const [funnelName, setFunnelName] = useState<string>('');
  const [funnelDescription, setFunnelDescription] = useState<string>('');
  const [selectedAssistants, setSelectedAssistants] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [funnels, setFunnels] = useState<Funnel[]>([]);
  const [selectedFunnel, setSelectedFunnel] = useState<Funnel | null>(null);
  const [showChatModal, setShowChatModal] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState<any>(null);
  const [channels, setChannels] = useState<Channel[]>([]);

  // Estado para almacenar el segundo número extraído del id de la conversación
  const [secondNumber, setSecondNumber] = useState<string | null>(null);
  // Estado para almacenar el asistente que coincide con el canal encontrado
  const [matchingAssistant, setMatchingAssistant] = useState<Assistant | null>(null);

  // STATE FOR DRAGGABLE COLUMNS (each column corresponds to a category)
  const [columns, setColumns] = useState<{ [key: string]: any[] }>({});
  // NEW: State for tracking column order
  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const [loadingConversationId, setLoadingConversationId] = useState<string | null>(null);
  const [isUpdatingCategory, setIsUpdatingCategory] = useState(false);

  const handleCardClick = (conversation: any) => {
    setSelectedConversation(conversation);
    setShowChatModal(true);
  };

  const fetchFunnels = async () => {
    setIsLoadingFunnels(true);
    const selectedFunnelId = selectedFunnel?._id;
    try {
      const response = await axios.get(
        `${config.backendURL}/get_funnels/${gojimx_businessId}`,
        {
          headers: {
            Authorization: `Bearer ${gojimx_token}`,
          },
        }
      );
      if (response.data.success) {
        setFunnels(response.data.funnels);
        if (funnels.length > 0 && selectedFunnelId) {
          setSelectedFunnel(response.data.funnels.find((f:Funnel) => f._id === selectedFunnelId));
        }
      }
    } catch (error) {
      console.error('Error fetching funnels:', error);
    } finally {
      setIsLoadingFunnels(false);
    }
  };

  useEffect(() => {
    fetchFunnels();
  }, []);

  const fetchCategories = async () => {
    setIsLoadingCategories(true);
    try {
      const response = await axios.get(
        `${config.backendURL}/get_category/${gojimx_businessId}`,
        {
          headers: {
            Authorization: `Bearer ${gojimx_token}`,
          },
        }
      );
      // Asumimos que response.data[0] es el documento de categorías a utilizar
      const options = response.data[0][0].options;
      const iconsMapping = options.reduce(
        (acc: { [key: string]: any }, option: { option_name: string; icon: any }) => {
          acc[option.option_name] = option.icon;
          return acc;
        },
        {}
      );
      setCategoryIcons(iconsMapping);
    } catch (error) {
      console.error('There was an error fetching the categories:', error);
    } finally {
      setIsLoadingCategories(false);
    }
  };

  useEffect(() => {
    const fetchStatus = async () => {
      const data = await fetchBusinessStatus();
      setAssistants(data.assistants);
      setChannels(data.channels);
    };
    fetchStatus();
    fetchCategories();
  }, []);

  // Automatically select the first funnel if available
  useEffect(() => {
    if (funnels.length > 0 && !selectedFunnel) {
      setSelectedFunnel(funnels[0]);
    }
  }, [funnels, selectedFunnel]);

  // When a funnel is selected, build columns based on its categories
  useEffect(() => {
    if (selectedFunnel) {
      const newColumns: { [key: string]: any[] } = {};
      selectedFunnel.categories.forEach((category: string) => {
        newColumns[category] = selectedFunnel.conversations
          ? selectedFunnel.conversations.filter((conv: any) => conv.category === category)
          : [];
      });
      setColumns(newColumns);
      // Set initial column order
      setColumnOrder(selectedFunnel.columnOrder && selectedFunnel.columnOrder.length > 0 ? selectedFunnel.columnOrder : selectedFunnel.categories);
    }
  }, [selectedFunnel]);

  const handleSupportChatOpen = (question: string) => {
    setPredefinedQuestion(question);
    setSupportChatOpen(true);
  };

  const handleSupportChatClose = () => setSupportChatOpen(false);

  const handleNewFunnelOpen = () => {
    setSelectedIcon('');
    setFunnelName('');
    setFunnelDescription('');
    setSelectedAssistants([]);
    setSelectedCategories([]);
    setShowNewFunnelModal(true);
  };
  const handleNewFunnelClose = () => setShowNewFunnelModal(false);

  const handleSaveFunnel = async () => {
    setIsCreatingFunnel(true);
    const funnelData = {
      funnel_name: funnelName,
      description: funnelDescription,
      business_id: gojimx_businessId,
      assistants: selectedAssistants,
      categories: selectedCategories,
      icon: selectedIcon,
      schema_version: '1.0',
    };

    try {
      const response = await axios.post(
        `${config.backendURL}/create_funnel`,
        funnelData,
        {
          headers: {
            Authorization: `Bearer ${gojimx_token}`,
          },
        }
      );
      if (response.data.success) {
        await fetchFunnels();
      }
    } catch (error) {
      console.error('Error saving funnel:', error);
    } finally {
      setIsCreatingFunnel(false);
      handleNewFunnelClose();
    }
  };

  const handleOpenEditFunnel = () => {
    if (selectedFunnel) {
      // Prepopulate the state with the selected funnel's data
      setSelectedIcon(selectedFunnel.icon || '');
      setFunnelName(selectedFunnel.funnel_name);
      setFunnelDescription(selectedFunnel.description);
      setSelectedAssistants(selectedFunnel.assistants || []);
      setSelectedCategories(selectedFunnel.categories || []);
      setShowEditFunnelModal(true);
    }
  };

  

  // Handler to update the funnel using a PUT request.
  const handleUpdateFunnel = async (updatedFunnelData: any) => {
    if (!selectedFunnel?._id) return;
    setIsUpdatingFunnel(true);
    const finalColumnOrder = (updatedFunnelData.columnOrder || columnOrder)
      .filter((column: any) => updatedFunnelData.categories.includes(column))
      .concat(
      updatedFunnelData.categories.filter(
        (category: any) => !(updatedFunnelData.columnOrder || columnOrder).includes(category)
      )
      );
    
    try {
      const dataWithColumnOrder = {
        ...updatedFunnelData,
        // Use the passed columnOrder if available; otherwise fallback to state.
        columnOrder: finalColumnOrder,
      };
  
      const response = await axios.put(
        `${config.backendURL}/update_funnel/${selectedFunnel._id}`,
        dataWithColumnOrder,
        {
          headers: {
            Authorization: `Bearer ${gojimx_token}`,
          },
        }
      );
      if (response.data.success) {
        await fetchFunnels();
      }
    } catch (error) {
      console.error('Error updating funnel:', error);
    } finally {
      setIsUpdatingFunnel(false);
      setShowEditFunnelModal(false);
    }
  };

  // Handler to delete the selected funnel
  const handleDeleteFunnel = async () => {
    if (!selectedFunnel?._id) return;
    setIsDeletingFunnel(true);
    try {
      const response = await axios.delete(
        `${config.backendURL}/delete_funnel/${selectedFunnel._id}`,
        {
          headers: {
            Authorization: `Bearer ${gojimx_token}`,
          },
        }
      );
      if (response.data.success) {
        await fetchFunnels();
        setSelectedFunnel(null);
      }
    } catch (error) {
      console.error('Error deleting funnel:', error);
    } finally {
      setIsDeletingFunnel(false);
      setShowEditFunnelModal(false);
    }
  };

  // Utility functions for toggling selections
  const toggleAssistantSelection = (assistantId: string) => {
    setSelectedAssistants((prev) =>
      prev.includes(assistantId) ? prev.filter((id) => id !== assistantId) : [...prev, assistantId]
    );
  };

  const toggleCategorySelection = (category: string) => {
    setSelectedCategories((prev) =>
      prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category]
    );
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const redirect = (pRoute: string) => {
    const currentUrl = window.location.pathname;
    const targetRoute = '/' + pRoute;
    if (currentUrl !== targetRoute) {
      setTimeout(() => {
        navigate(targetRoute);
      }, 300);
    } else {
      handleClose();
    }
  };

  // Extraer el segundo número del id de la conversación seleccionada
  React.useEffect(() => {
    if (selectedConversation && selectedConversation.id) {
      const parts = selectedConversation.id.split('@s.whatsapp.net');
      if (parts.length > 1) {
        setSecondNumber(parts[1]);
      } else {
        setSecondNumber(null);
      }
    } else {
      setSecondNumber(null);
    }
  }, [selectedConversation]);

  // Buscar el canal y el asistente que coincidan según el segundo número extraído
  React.useEffect(() => {
    if (secondNumber) {
      const matchingChannel = channels.find(channel => channel.username === secondNumber);
      const assistant = assistants.find(
        assistant => assistant.assistant_id === matchingChannel?.assistant_id
      );
      setMatchingAssistant(assistant || null);
    } else {
      setMatchingAssistant(null);
    }
  }, [secondNumber, channels, assistants]);

  // NEW: Handler for when a card is dragged and dropped
  const onDragEndCards = async (result: any) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index)
      return;

    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = Array.from(sourceColumn);
    const [removed] = sourceItems.splice(source.index, 1);

    // Optimistic UI update: update state immediately
    if (source.droppableId === destination.droppableId) {
      sourceItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: sourceItems,
      });
    } else {
      const destItems = Array.from(destColumn);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: sourceItems,
        [destination.droppableId]: destItems,
      });
    }

    // If moving to a different column, update the conversation's category on the backend.
    if (source.droppableId !== destination.droppableId) {
      removed.category = destination.droppableId;
      setLoadingConversationId(removed._id);
      try {
        await axios.put(
          `${config.backendURL}/update_conversation_category/${removed._id}`,
          { newCategory: destination.droppableId },
          {
            headers: {
              Authorization: `Bearer ${gojimx_token}`,
            },
          }
        );
      } catch (err) {
        console.error("Error updating conversation category:", err);
      }
      setLoadingConversationId(null);
    }
  };

  // NEW: Handler for when a column is dragged and dropped
  const onDragEndColumns = async (result: any) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === destination.index) return;
  
    const newColumnOrder = Array.from(columnOrder);
    const [removed] = newColumnOrder.splice(source.index, 1);
    newColumnOrder.splice(destination.index, 0, removed);
  
    setColumnOrder(newColumnOrder);
  
    // Call handleUpdateFunnel with the new column order
    if (selectedFunnel) {
      const updatedFunnelData = {
        funnel_name: selectedFunnel.funnel_name,
        description: selectedFunnel.description,
        assistants: selectedFunnel.assistants,
        categories: selectedFunnel.categories,
        icon: selectedFunnel.icon,
        schema_version: selectedFunnel.schema_version,
        columnOrder: newColumnOrder, // include the updated order explicitly
      };
      await handleUpdateFunnel(updatedFunnelData);
    }
  };

  // NEW: Main handler that delegates to the appropriate handler based on the type
  const onDragEnd = (result: any) => {
    if (result.type === 'COLUMN') {
      onDragEndColumns(result);
    } else {
      onDragEndCards(result);
    }
  };

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      <div id="topbar">
        <TopBar handleShow={handleShow} />
      </div>
      <div className="home-layout">
        <Sidebar redirect={redirect} />
        <div className="main-content">
          <div className="row ">
            <div className="row mb-3 animate__animated animate__fadeIn pt-4 px-5">
              <div className="d-flex justify-content-between align-items-center w-100">
                <div >
                  <h2 className="fw-bold text-dark">Embudos</h2>
                </div>
                <div>
                  <Button
                    className="shadow-sm px-5 py-2 me-2"
                    onClick={handleNewFunnelOpen}
                    disabled={isCreatingFunnel}
                    style={{ backgroundColor: '#B9E6FF', border: 'none' }}
                  >
                    {isCreatingFunnel ? (
                      <>
                        <Spinner animation="border" size="sm" className="me-2" />
                        Creando...
                      </>
                    ) : (
                      "Nuevo embudo +"
                    )}
                  </Button>
                </div>
              </div>
              {selectedFunnel ? (
                <p>{selectedFunnel.description}</p>
              ) : (
                <p>Filtra y toma el control de tus conversaciones en un solo lugar.</p>
              )}

              <div className='px-3'>
                {funnels.length > 0 && (
                  <Form.Select
                  style={{ display: 'inline-block', width: 'auto' }}
                  value={selectedFunnel?._id || ''}
                  className='shadow-sm'
                  onChange={(e) => {
                    const funnelId = e.target.value;
                    const funnel = funnels.find((f) => f._id === funnelId);
                    setSelectedFunnel(funnel || null);
                  }}
                  >
                  {funnels.map((funnel) => (
                    <option key={funnel._id} title={funnel.funnel_name} value={funnel._id}>
                    {funnel.funnel_name} {funnel.icon && funnel.icon}
                    </option>
                  ))}
                  </Form.Select>
                )}
                {selectedFunnel && (
                  <Button
                    onClick={handleOpenEditFunnel}
                    disabled={isUpdatingFunnel}
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      textDecoration: 'underline',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                   
                      <>Editar embudo <i className="bi bi-pencil"></i></>
                  </Button>
                )}
              </div>
            </div>
            {!selectedFunnel && (
              <div
                className="animate__animated animate__fadeIn"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  opacity: 1,
                  transition: 'opacity 0.5s ease-in-out',
                }}
              >
                <h1 style={{ color: 'gray' }}>Bienvenido 👋</h1>
                <p style={{ color: 'gray' }}>
                  Aquí visualizarás tus embudos. ¡Configura uno{' '}
                  <span
                    style={{ color: 'blue', cursor: 'pointer' }}
                    onClick={handleNewFunnelOpen}
                  >
                    aquí
                  </span>
                  !
                </p>
              </div>
            )}

            {/* Kanban Board with nested drag contexts */}
            {selectedFunnel && (
              <div className="kanban-board animate__animated animate__fadeIn" style={{ overflowX: 'auto' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Container fluid>
                    <Droppable droppableId="board" direction="horizontal" type="COLUMN">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            gap: '1rem',
                            overflowX: 'auto',
                          }}
                        >
                          {columnOrder.map((categoryId, index) => {
                            const categoryConversations = columns[categoryId] || [];
                            return (
                              <Draggable key={categoryId} draggableId={categoryId} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      flex: '0 0 300px',
                                      ...provided.draggableProps.style,
                                      opacity: snapshot.isDragging ? 0.8 : 1,
                                    }}
                                    
                                  >
                                    <Droppable droppableId={categoryId} type="CARD">
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                          style={{
                                            borderRadius: '8px',
                                            transition: 'background-color 0.2s ease, transform 0.2s ease, padding 0.2s ease',
                                          }}
                                          
                                        >
                                          <KanbanList
                                            id={categoryId}
                                            title={categoryId}
                                            isTitleEditable={false}
                                            className={`kanban-title-container ${snapshot.isDraggingOver ? 'being-dragged-over' : ''}`}
                                          >
                                            {categoryConversations.map((conv, index) => {
                                              const parts = conv.id.split('@s.whatsapp.net');
                                              const secondNumberLocal = parts.length > 1 ? parts[1] : null;
                                              const matchingChannelLocal = channels.find(
                                                (channel) => channel.username === secondNumberLocal
                                              );
                                              const conversationAssistant = assistants.find(
                                                (assistant) =>
                                                  assistant.assistant_id === matchingChannelLocal?.assistant_id
                                              );
                                              return (
                                                <Draggable draggableId={conv._id} index={index} key={conv._id}>
                                                  {(provided, snapshot) => (
                                                    <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                    >
                                                      <KanbanCard
                                                        user_name={conv.user_name}
                                                        user_phone={conv.user_phone}
                                                        threadId={conv.threadId}
                                                        renata_sleep={conv.renata_sleep}
                                                        assistant={conversationAssistant?.name}
                                                        onClick={() => handleCardClick(conv)}
                                                        loading={loadingConversationId === conv._id}
                                                      />
                                                    </div>
                                                  )}
                                                </Draggable>
                                              );
                                            })}
                                            {provided.placeholder}
                                          </KanbanList>
                                        </div>
                                      )}
                                    </Droppable>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Container>
                </DragDropContext>
              </div>
            )}
          </div>
        </div>
      </div>
      <Menu show={show} handleClose={handleClose} />

      {gojimx_payment_type === 'Premium' && (
        <>
          <div
            className="support-bubble animate__animated animate__fadeIn"
            onClick={() => handleSupportChatOpen('')}
          >
            💬
          </div>
          <SupportChatModal
            show={supportChatOpen}
            handleClose={handleSupportChatClose}
            predefinedQuestion={predefinedQuestion}
          />
        </>
      )}
      <NewFunnelModal
        show={showNewFunnelModal}
        onClose={handleNewFunnelClose}
        onSave={handleSaveFunnel}
        isSaving={isCreatingFunnel}
        selectedIcon={selectedIcon}
        setSelectedIcon={setSelectedIcon}
        funnelName={funnelName}
        setFunnelName={setFunnelName}
        funnelDescription={funnelDescription}
        setFunnelDescription={setFunnelDescription}
        assistants={assistants}
        selectedAssistants={selectedAssistants}
        setSelectedAssistants={setSelectedAssistants}
        categoryIcons={categoryIcons}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      {selectedFunnel && (
        <EditFunnelModal
          show={showEditFunnelModal}
          onClose={() => setShowEditFunnelModal(false)}
          onSave={handleUpdateFunnel}
          onDelete={handleDeleteFunnel}
          isSaving={isUpdatingFunnel}
          isDeleting={isDeletingFunnel}
          funnel={selectedFunnel}
          assistants={assistants}
          categoryIcons={categoryIcons}
          selectedAssistants={selectedAssistants}
          setSelectedAssistants={setSelectedAssistants}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedIcon={selectedIcon}
          setSelectedIcon={setSelectedIcon}
          funnelName={funnelName}
          setFunnelName={setFunnelName}
          funnelDescription={funnelDescription}
          setFunnelDescription={setFunnelDescription}
        />
      )}

      {/* Conversation Chat Modal */}
      {selectedConversation && (
        <ConversationChatModal
          show={showChatModal}
          onHide={() => setShowChatModal(false)}
          threadId={selectedConversation.threadId}
          user_name={selectedConversation.user_name}
          user_phone={selectedConversation.user_phone}
          conversationId={selectedConversation.id}
          summary={selectedConversation.summary}
          category={selectedConversation.category}
        />
      )}
    </div>
  );
};

export default Funnels;