import React, { useEffect, useState } from 'react';
import { Button, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './css/main.css';
import './css/topbar.css';
import Logo from './img/renata-logo-new.svg';
import axios from 'axios';
import config from './config/config';
import StoreModal from './Components/Modals/StoreModal';
import loadingGif from './img/loading.gif';

interface TopBarProps {
  handleShow: () => void;
  refresh?: boolean;
}

function TopBar({ handleShow, refresh }: TopBarProps) {
  const [currentPoints, setCurrentPoints] = useState<number>(0);
  const [showStoreModal, setShowStoreModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    const fetchBusinessInfo = async () => {
      const gojimx_businessId = localStorage.getItem('gojimx_businessId');
      const gojimx_token = localStorage.getItem('gojimx_token');

      try {
        const response = await axios.post(
          `${config.backendURL}/getRenataPoints`,
          { businessId: gojimx_businessId },
          {
            headers: {
              'Authorization': `Bearer ${gojimx_token}`
            }
          }
        );
        
        // Set points and current payment plan
        localStorage.setItem('gojimx_payment_type', response.data.payment_type);
        setCurrentPoints(Number(response.data.renata_points.toFixed(0)));

        setLoading(false);
      } catch (error) {
        console.error('Error fetching business info:', error);
        setLoading(false);
      }
    };

    fetchBusinessInfo();
  }, [refresh]);

  const formatNumberWithCommas = (number: number) => {
    return new Intl.NumberFormat().format(number);
  };

  const handleStoreModalShow = () => setShowStoreModal(true);
  const handleStoreModalClose = () => setShowStoreModal(false);

  return (
    <>
      <Navbar className="px-2 py-2 navbar-glass" expand="lg">
        <Navbar.Brand className='px-4' onClick={redirectToHome} style={{ cursor: 'pointer' }}>
          <img className="logo-size" src={Logo} alt="Renata Logo" />
        </Navbar.Brand>
        {/* <div className='version-text'>V1.3</div> */}
        <div className="ms-auto d-flex align-items-center">
          <div className="me-3 p-3 btn btn-outline-light text-dark border-0 d-flex" onClick={handleStoreModalShow}>
            <i className='bi bi-x-diamond-fill text-primary'></i>
            {loading ? (
              <img className='loading mx-2' src={loadingGif} alt="Cargando..." />
            ) : (
              <span className='ms-2'><samp>{formatNumberWithCommas(currentPoints)}</samp></span>
            )}
          </div>
          <div className="d-lg-none btn btn-lg bg-transparent btn-light border border-2 rounded" onClick={handleShow}>
            <i className="bi bi-list" style={{ fontSize: '1.2em' }}></i>
          </div>
        </div>
      </Navbar>

      {/* Store Modal */}
      <StoreModal show={showStoreModal} handleClose={handleStoreModalClose} />
    </>
  );
}

export default TopBar;