import React, { useEffect, useState } from 'react';
import handleLogout, { fetchBusinessStatus, validateToken } from '../../functions/functions';
import { Offcanvas, Button } from 'react-bootstrap';
import '../../css/main.css'
import Logo from '../../img/renata-logo.svg';
import { useNavigate } from 'react-router-dom';

interface MenuProps {
  show: boolean;
  handleClose: () => void;
}

function Menu({ show, handleClose }: MenuProps) {
  const gojimx_phone = localStorage.getItem('gojimx_phone');

  const navigate = useNavigate();

  useEffect(() => {
    const validate = async () => {
      await validateToken();
    }
    validate();
  }, []);

  //Navigate only if url is different
  const redirect = (pRoute: string) => {
    const currentUrl = window.location.pathname; 
    const targetRoute = '/' + pRoute;
  
    if (currentUrl !== targetRoute) {
      navigate(targetRoute);
    }
    else {
      handleClose();
    }
  };

  useEffect(() => {
    fetchBusinessStatus();
  }, [])
  

  return (
    <Offcanvas className='px-4 py-2' show={show} onHide={handleClose} placement="end">
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>
        <div className='row text-start p-0'>
          <div className='col p-0'>
            <img className="logo-size" src={Logo} alt="Renata Logo" />
          </div>
        </div>
      </Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className="d-flex flex-column">
      <div className='w-100 mb-3'>
        <div className='row mb-3'>
          <Button className="w-100 text-start" variant="light" onClick={() => redirect('home')}>Inicio</Button>
        </div>
        <div className='row mb-3'>
          <Button className="w-100 text-start" variant="light" onClick={() => redirect('myschedule')}>Mi Agenda</Button>
        </div>
        <div className='row mb-3'>
          <Button className="w-100 text-start" variant="light" onClick={() => redirect('myconversations')}>Mis Conversaciones</Button>
        </div>
        <div className='row mb-3'>
          <Button className="w-100 text-start" variant="light" onClick={() => redirect('myexpenses')}>Consumo</Button>
        </div>
        <div className='row mb-3'>
          <Button className="w-100 text-start" variant="light" onClick={() => redirect('statistics')}>Estadísticas</Button>
        </div>
        <div className='row mb-3'>
          <Button className="w-100 text-start" variant="light" onClick={() => redirect('settings')}>Configuración</Button>
        </div>
        <div className='row'>
          <Button className="w-100 text-danger text-start" variant="light" onClick={handleLogout}>Cerrar Sesión</Button>
        </div>
      </div>
      <div className="mt-auto row mb-3">
        <Button className="w-100 text-danger" variant="light" onClick={() => redirect('delete-account')}>Desactivar cuenta</Button>
      </div>
    </Offcanvas.Body>
  </Offcanvas>
  );
}

export default Menu;
