// src/components/Funnels/NewFunnelModal.tsx

import React from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { Assistant } from '../Conversations/RightColumn';

interface NewFunnelModalProps {
  show: boolean;
  onClose: () => void;
  onSave: () => void;
  isSaving?: boolean;

  // Fields for the funnel data
  selectedIcon: string;
  setSelectedIcon: React.Dispatch<React.SetStateAction<string>>;

  funnelName: string;
  setFunnelName: React.Dispatch<React.SetStateAction<string>>;

  funnelDescription: string;
  setFunnelDescription: React.Dispatch<React.SetStateAction<string>>;

  assistants: Assistant[]; // The list of available assistants

  selectedAssistants: string[];
  setSelectedAssistants: React.Dispatch<React.SetStateAction<string[]>>;

  categoryIcons: { [key: string]: any };

  selectedCategories: string[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
}

const NewFunnelModal: React.FC<NewFunnelModalProps> = ({
  show,
  onClose,
  onSave,
  isSaving = false,
  selectedIcon,
  setSelectedIcon,
  funnelName,
  setFunnelName,
  funnelDescription,
  setFunnelDescription,
  assistants,
  selectedAssistants,
  setSelectedAssistants,
  categoryIcons,
  selectedCategories,
  setSelectedCategories,
}) => {
  // Toggling selection for an assistant
  const toggleAssistantSelection = (assistantId: string) => {
    setSelectedAssistants(prev =>
      prev.includes(assistantId)
        ? prev.filter(id => id !== assistantId)
        : [...prev, assistantId]
    );
  };

  // Toggling selection for a category
  const toggleCategorySelection = (category: string) => {
    setSelectedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const iconOptions = [
    "🔴",
    "🟡",
    "🟢",
    "🔵",
    "🚨",
    "💯",
    "👍",
    "👎",
    "🔥",
    "😄",
    "😞",
    "😐",
    "😠",
    "🌟",
    "🚀",
    "🎉",
    "💡",
    "📈",
    "📉",
    "🔒",
    "🔓",
    "🛠️",
    "⚙️",
    "💬",
    "📧",
    "📞",
    "🏆",
    "🎯",
    "📅",
    "📝"
  ];

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Nuevo embudo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Icon Selector */}
        <Form.Group className="mb-3">
          <Form.Label><strong>Ícono:</strong></Form.Label>
          <Form.Select
            value={selectedIcon}
            onChange={(e) => setSelectedIcon(e.target.value)}
          >
            <option value="">Selecciona un ícono...</option>
            {iconOptions.map((icon) => (
              <option key={icon} value={icon}>
                {icon}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        {/* Name */}
        <Form.Group className="mb-3">
          <Form.Label><strong>Nombre:</strong></Form.Label>
          <Form.Control
            type="text"
            placeholder="Nombre del embudo"
            value={funnelName}
            onChange={(e) => setFunnelName(e.target.value)}
          />
        </Form.Group>

        {/* Description */}
        <Form.Group className="mb-3">
          <Form.Label><strong>Descripción:</strong></Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="¿De qué trata tu embudo?"
            value={funnelDescription}
            onChange={(e) => setFunnelDescription(e.target.value)}
          />
        </Form.Group>

        {/* Assistants */}
        <Form.Group className="mb-3">
          <Form.Label><strong>Asistentes:</strong></Form.Label>
          <div className="d-flex flex-wrap">
            {assistants.map((assistant) => (
              <Button
                key={assistant.assistant_id}
                variant={
                  selectedAssistants.includes(assistant.assistant_id)
                    ? 'primary'
                    : 'outline-primary'
                }
                className="me-2 mb-2"
                onClick={() => toggleAssistantSelection(assistant.assistant_id)}
              >
                {assistant.name}
              </Button>
            ))}
          </div>
        </Form.Group>

        {/* Categories */}
        <Form.Group className="mb-3">
          <Form.Label><strong>Categorías:</strong></Form.Label>
          <div className="d-flex flex-wrap">
            {Object.keys(categoryIcons).map((category) => (
              <Button
                key={category}
                variant={
                  selectedCategories.includes(category)
                    ? 'success'
                    : 'outline-success'
                }
                className="me-2 mb-2"
                onClick={() => toggleCategorySelection(category)}
              >
                {category}
              </Button>
            ))}
          </div>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={onSave} disabled={isSaving}>
          {isSaving ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Guardando...
            </>
          ) : (
            "Guardar"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewFunnelModal;