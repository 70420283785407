import React, { useEffect, useState } from "react";
import { Form, ListGroup } from "react-bootstrap";
import '../../css/kanban.css';

// Function to return a random preset pastel class
const randomPastelClass = () => {
  const classes = [
    "pastel-1",
    "pastel-2",
    "pastel-3",
    "pastel-4",
    "pastel-5",
    "pastel-6",
    "pastel-7",
    "pastel-8",
  ];
  return classes[Math.floor(Math.random() * classes.length)];
};

const KanbanList = (props) => {
  const { id, listRef, extraProps = {} } = props;
  const [title, setTitle] = useState(props.title ?? "");
  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const [pastelClass, setPastelClass] = useState("");

  useEffect(() => {
    setPastelClass(randomPastelClass());
  }, []);

  const onTitleChange = () => {
    props.onTitleChange && props.onTitleChange({ id, title });
    setIsTitleEditable(false);
  };

  const toggleIsTitleEditable = () => {
    setIsTitleEditable(!isTitleEditable);
  };

  return (
    <div
      ref={listRef}
      {...extraProps}
      style={{ margin: 0, padding: 0 }}
      
    >
      <div
        className={`d-flex justify-content-between align-items-center kanban-title-container ${pastelClass} mb-2`}
      >
        {isTitleEditable ? (
          <Form.Group id="title" className="w-100">
            <Form.Control
              autoFocus
              value={title}
              className=" fs-6 fw-bold p-2 m-0 lh-1 border-0"
              onChange={(e) => setTitle(e.target.value)}
              onFocus={(e) => e.target.select()}
              onBlur={onTitleChange}
            />
          </Form.Group>
        ) : (
          <h5
            className="kanban-title d-flex align-items-center w-100 fs-6 fw-bold p-2 m-0"
            onClick={toggleIsTitleEditable}
          >
            {title}
          </h5>
        )}
      </div>
      <ListGroup className="m-0 p-0">{props.children}</ListGroup>
    </div>
  );
};

export default KanbanList;