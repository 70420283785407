import React, { useState, ChangeEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from './img/renata-logo.svg';
import loadingGif from './img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/login.css';
import axios from 'axios';
import ConfirmationModal from './Components/Modals/ConfirmationModal';
import config from './config/config';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalType, setModalType] = useState('');

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setEmailError(false);
    setModalText('');

    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      setIsLoading(false);
      return;
    }

    try {
      await axios.post(`${config.backendURL}/send_email_password_recover`, { email });
      setModalText(
        'Si algún negocio coincide con esa cuenta de correo, se te enviará un correo con un enlace para recuperar tu contraseña.'
      );
      setModalType('✅');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 500) {
          setModalType('❌');
          setModalText('Error interno del servidor, por favor inténtalo nuevamente más tarde.');
        } else {
          setModalText(
            'Si algún negocio coincide con esa cuenta de correo, se te enviará un correo con un enlace para recuperar tu contraseña.'
          );
          setModalType('✅');
        }
      } else {
        setModalType('❌');
        setModalText('Se produjo un error, por favor verifica tu conexión a internet o inténtalo nuevamente más tarde.');
      }
    } finally {
      setIsLoading(false);
      setModalShow(true);
    }
  };

  return (
    <div className="container-login-outer">
      <div className="login-container">
        <div className="background-login">
          <svg viewBox="0 0 1280 832" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M640 585.096L419.496 359.9C263.529 200.616 0 313.214 0 538.409V832.262H1280V-68.5209L640 585.096Z"
              fill="#D6E1DF"
            />
          </svg>
        </div>
        <div className="animate__animated animate__jackInTheBox login-box px-custom-login">
        <p className="font-light title-login mb-4">
            Recuperar Contraseña
          </p>
          <form noValidate className="w-100 p-0 " onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="email"
                className={`form-control bg-light centered-placeholder centered-input-container d-flex ${emailError ? 'is-invalid' : ''}`}
                placeholder="Correo Electrónico"
                onChange={handleEmailChange}
                autoComplete="off"
                value={email}
              />
              {emailError && <div className="invalid-feedback">Por favor, introduce un correo electrónico válido.</div>}
            </div>
            <button  type="submit" className="btn btn-success w-100 rounded"disabled={isLoading}>
              {isLoading ? <img className="loading" src={loadingGif} alt="Cargando..." /> : 'Enviar'}
            </button>
            <div className="mt-2 text-center">
              <span className="fs-6">
                ¿No tienes una cuenta? <Link to="/register" className="text-primary">Regístrate</Link>
              </span>
            </div>
            <div className="text-center">
              <span className="fs-6">
                ¿Ya tienes una cuenta? <Link to="/login" className="text-primary">Inicia sesión</Link>
              </span>
            </div>
          </form>
          <ConfirmationModal
            show={modalShow}
            onHide={() => navigate('/login')}
            text={modalText}
            type={modalType}
            isLoading={isLoading}
            titleText="Recuperar Contraseña"
          />
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;