import React, { useState, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import Logo from './img/renata-logo.svg';
import loadingGif from './img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/login.css';
import axios from 'axios';
import config from './config/config';

function Login() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState('');

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (event.target.value.trim()) {
      setPasswordError(false);
    }
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    // Remove non-digit characters
    value = value.replace(/\D/g, '');

    // Check prefix and format accordingly
    const prefix = value.slice(0, 2);
    if (prefix === '55' || prefix === '56' || prefix === '33' || prefix === '81') {
      if (value.length > 2 && value.length <= 6) {
        value = value.slice(0, 2) + ' ' + value.slice(2);
      } else if (value.length > 6) {
        value = value.slice(0, 2) + ' ' + value.slice(2, 6) + ' ' + value.slice(6, 10);
      }
    } else {
      if (value.length > 3 && value.length <= 6) {
        value = value.slice(0, 3) + ' ' + value.slice(3);
      } else if (value.length > 6) {
        value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6, 10);
      }
    }

    setIsValidNumber(value.length === 12);
    setPhoneNumber(value);
    if (event.target.value.trim()) {
      setPhoneError(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isValid = true;
    setIsLoading(true);

    if (!isValidNumber) {
      setPhoneError(true);
      isValid = false;
    }

    if (!password.trim()) {
      setPasswordError(true);
      isValid = false;
    }

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    axios.post(`${config.backendURL}/login`, {
      phoneNumber: phoneNumber,
      password: password
    })
      .then(async response => {
        // Store session data
        localStorage.setItem('gojimx_token', response.data.token);
        localStorage.setItem('gojimx_phone', response.data.phoneNumber);
        localStorage.setItem('gojimx_username', response.data.username);
        localStorage.setItem('gojimx_businessId', response.data.businessId);
        localStorage.setItem('gojimx_business_name', response.data.business_name);
        localStorage.setItem('gojimx_assistant_id', response.data.assistant_id);
        localStorage.setItem('gojimx_baileys_status', response.data.baileys_status);
        localStorage.setItem('gojimx_baileys_port', response.data.baileys_port);
        localStorage.setItem('gojimx_email', response.data.email);
        localStorage.setItem('gojimx_payment_type', response.data.payment_type);
        localStorage.setItem('gojimx_needs_payment', (Number(response.data.renata_points) <= 0).toString());
        localStorage.setItem('gojimx_user_status', response.data.user_status);

        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('gojimx_token')}`;
        const email = response.data.email;
        const user_status = response.data.user_status;
        const business_id = response.data.businessId;
        const dev_env = config.backendURL === "https://development.goji-mx.cloud/backend"
          ? "development"
          : config.backendURL === "https://quality.goji-mx.cloud/backend"
            ? "quality"
            : "main";

        if (email && (Number(response.data.renata_points) <= 0) && user_status !== 'idle') {
          const obj = {
            email: email,
            subjectData: "Recordatorio de Pago Pendiente",
            text: "Estimado usuario, " + response.data.username + "\n\nNos dirigimos a usted para recordarle que su pago está pendiente. Para poder seguir disfrutando de nuestros servicios sin interrupciones, le solicitamos que realice el pago lo antes posible.\n\nA continuación, encontrará los detalles de su transacción: \n\n• Número de teléfono del usuario: " + response.data.businessId + "\n\n• Nombre del negocio: " + response.data.business_name + "\n\n• Estado del pago: Pendiente \n\nPara realizar el pago, por favor acceda a su cuenta y siga las instrucciones de pago proporcionadas. En https://" + dev_env + ".goji-mx.cloud/  \n\nSi tiene alguna pregunta o necesita asistencia adicional, no dude en contactarnos en contacto@goji.mx. \n\nGracias por su comprensión y cooperación.\n\nAtentamente,\n\nEl equipo de Goji.mx",
            businessId: business_id
          };
          await axios.post(`${config.backendURL}/send_email`, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('gojimx_token')}`,
              'Content-Type': 'application/json',
            }
          }).catch(error => {
            console.error("Error sending email:", error);
          });
        } else if (email && user_status === 'idle') {
          const obj = {
            email: email,
            subjectData: "Inicio de sesión en su cuenta desactivada",
            text: "Estimado usuario, " + response.data.username + "\n\nNos dirigimos a usted para notificarle que se inició sesión en su cuenta desactivada.\n\nPara volver a utilizar su cuenta, por favor acceda a su cuenta y siga las instrucciones de pago proporcionadas. En https://" + dev_env + ".goji-mx.cloud/  \n\nSi tiene alguna pregunta o necesita asistencia adicional, no dude en contactarnos en contacto@goji.mx. \n\nGracias por su comprensión y cooperación.\n\nAtentamente,\n\nEl equipo de Goji.mx",
            businessId: business_id
          };
          await axios.post(`${config.backendURL}/send_email`, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('gojimx_token')}`,
              'Content-Type': 'application/json',
            }
          }).catch(error => {
            console.error("Error sending email:", error);
          });
        }
        setIsLoading(false);
        window.location.reload();
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            setLoginError('Credenciales incorrectas, por favor verifica tu número de teléfono y contraseña.');
          } else if (error.response.status === 500) {
            setLoginError('Error interno del servidor, por favor inténtalo nuevamente más tarde.');
          }
        } else {
          setLoginError('Se produjo un error, por favor verifica tu conexión a internet o inténtalo nuevamente más tarde.');
        }
        setIsLoading(false);
      });
  };

  return (
    <div className="container-login-outer">
      <div className="login-container">
        <div className="background-login">
          <svg
            viewBox="0 0 1280 832"
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M640 585.096L419.496 359.9C263.529 200.616 0 313.214 0 538.409V832.262H1280V-68.5209L640 585.096Z"
              fill="#D6E1DF"
            />
          </svg>
        </div>
        <img src={Logo} alt="Renata Logo" className="login-logo" />
        <div className="animate__animated animate__jackInTheBox login-box px-custom-login">
          <p className="font-light title-login mb-4">Iniciar Sesión</p>
          <form noValidate onSubmit={handleSubmit}>
            <div className="mb-3">
              <label>Número de teléfono</label>

              <input
                type="tel"
                className={`form-control rounded centered-placeholder ${phoneError ? 'is-invalid' : ''}`}
                placeholder="Número de teléfono"
                value={phoneNumber}
                onChange={handlePhoneChange}
              />
              {phoneError && <div className="invalid-feedback">Por favor, introduce un teléfono válido.</div>}
            </div>
            <div className="mb-3">
              {isValidNumber && (
                <>
                  <label>Contraseña</label>

                  <input
                    type="password"
                    className={`form-control rounded centered-placeholder ${passwordError ? 'is-invalid' : ''}`}
                    placeholder="Contraseña"
                    onChange={handlePasswordChange}
                    autoComplete="off"
                  />
                </>
              )}
              {passwordError && <div className="invalid-feedback">Por favor, introduce una contraseña.</div>}
            </div>
            <button type="submit" className="btn btn-success w-100 rounded" disabled={isLoading}>
              {isLoading ? <img className="loading" src={loadingGif} alt="Cargando..." /> : 'Iniciar Sesión'}
            </button>
            {loginError && <div className="alert alert-danger mt-3">{loginError}</div>}
          </form>

          <div className="text-center mt-3">
            <span className="fs-6">
              <Link to="/forgot-password" className="text-black"> ¿Olvidaste tu contraseña? </Link>
            </span>
          </div>

          <div className="mt-5 text-center">
            <span className="fs-6">
              ¿No tienes una cuenta? <Link to="/register" className="text-black">Regístrate</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;