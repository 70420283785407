import React from "react";
import { Card, Spinner, Form } from "react-bootstrap";
import axios from "axios";
import config from "../../config/config";
import ConfirmationModal from "../Modals/ConfirmationModal";
import Logo from "../../img/renata-settings.png";

export default (props) => {
  const {
    loading, // New loading prop
    cardRef,
    user_name,
    user_phone,
    style = {},
    extraProps = {},
    threadId,
    renata_sleep = false,
    onClick,
    assistant,
  } = props;
  const [isRenataSleep, setIsRenataSleep] = React.useState(renata_sleep);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState("");
  const [confirmModalType, setConfirmModalType] = React.useState("✅");

  const gojimx_token = localStorage.getItem("gojimx_token");

  // Extract the phone number from user_phone string
  const extractedPhone = user_phone ? user_phone.split("@")[0] : "";

  const handleRenataSleepChange = async (e) => {
    e.stopPropagation(); // Prevent the container click event
    const newRenataSleepStatus = !e.target.checked;

    const url = `${config.backendURL}/updateRenataSleep`;
    const requestBody = {
      threadId: threadId,
      renata_sleep: newRenataSleepStatus,
    };

    try {
      await axios.post(url, requestBody, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`,
          "Content-Type": "application/json",
        },
      });
      setConfirmModalType("✅");
      setModalMessage(`Renata ha sido ${newRenataSleepStatus ? "apagada" : "encendida"} en esta conversación.`);
      setIsModalOpen(true);
      setIsRenataSleep(newRenataSleepStatus);
    } catch (error) {
      console.error("Error updating Renata sleep status:", error);
      setConfirmModalType("❌");
      setModalMessage("Failed to update Renata sleep status. Please try again.");
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <div
        ref={cardRef}
        className="shadow-sm position-relative"
        style={{
          border: "1px solid #ddd",
          borderRadius: "20px",
          cursor: "pointer",
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 30,
          paddingRight: 15,
          ...style,
        }}
        onClick={onClick}
        {...extraProps}
      >
        {/* Loading overlay always rendered and transitions opacity over 1s */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.7)",
            zIndex: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "20px",
            opacity: loading ? 1 : 0,
            transition: "opacity 1s ease",
            pointerEvents: loading ? "auto" : "none",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>

        <div className="d-flex flex-column justify-content-between px-0">
          {/* Top Row: Name & Toggle */}
          <div className="d-flex align-items-center justify-content-between mb-2 gap-2">
            <div style={{ flex: 1, minWidth: 0 }}>
              <h5 className="mb-0 fw-bold text-truncate">
                {user_name === "<desconocido>" ? "Sin nombre" : user_name}
              </h5>
            </div>
            <Form.Check 
              type="switch"
              id={`toggle-user-${extractedPhone}`}
              label=""
              className="form-switch-lg"
              style={{ fontSize: "1.1rem" }}
              checked={!isRenataSleep}
              onChange={handleRenataSleepChange}
              onClick={(e) => e.stopPropagation()}
            />
          </div>

          {/* Middle Row: Phone number */}
          <div className="mb-3">
            <span style={{ fontSize: "0.95rem", color: "#555" }}>
              {extractedPhone}
            </span>
          </div>

          {/* Bottom Row: Assistant name (placeholder) */}
          <div className="d-flex justify-content-between gap-2">
            <span style={{ fontSize: "0.9rem", fontWeight: 500 }}>
              <img src={Logo} alt="Assistant Logo" style={{ width: "25px", marginRight: "5px" }} />
              {assistant}
            </span>
          </div>
        </div>
      </div>

      <ConfirmationModal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        text={modalMessage}
        type={confirmModalType}
        titleText="Resultado de la Operación"
      />
    </>
  );
};