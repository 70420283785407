import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import AdvancedFilters from './AdvancedFilters';
import { formatPhoneNumber } from '../../functions/functions';
import loadingGif from '../../img/loading.gif';
import { CSSTransition, TransitionGroup, Transition } from 'react-transition-group';
import '../../css/leftcolumn.css';

interface CategoryIcons {
  [key: string]: string | undefined;
}

interface Assistant {
  assistant_id: string;
  name: string;
  description: string;
  intelligenceLevel: string;
  responseLength: string;
  workingHoursStart: number;
  workingHoursEnd: number;
  responseSpeed: string;
  instructions: string;
  active: boolean;
  active_skills: any[];
  assistant_knowledge_base: string;
  whitelist: any[];
  _id: string;
  voice?: string;
}

interface Channel {
  channel_name: string;
  active: boolean;
  username: string;
  baileys_port: string;
  baileys_status: boolean;
  main_number: boolean;
  channel_type: string;
  _id: string;
  assistant_id: string;
}

interface Conversation {
  category: string;
  id: string;
  threadId: string;
  business_id: string;
  summary: string;
  user_name: string;
  user_phone: string;
  renata_sleep: boolean;
  active_conversation: boolean;
  last_message_date: string;
}

interface LeftColumnProps {
  conversations: Conversation[];
  categoryIcons: CategoryIcons;
  selectedConversationPreview: Conversation | undefined;
  setSelectedConversationPreview: React.Dispatch<React.SetStateAction<Conversation | undefined>>;
  handleShowModalCarga: () => void;
  handleShowModalMensaje: () => void;
  exportToExcel: (conversations: Conversation[]) => void;
  gojimx_businessId: string | null;
  gojimx_token: string | null;
  filterFecha: string | null;
  setFilterFecha: React.Dispatch<React.SetStateAction<string | null>>;
  filterEstado: string | null;
  setFilterEstado: React.Dispatch<React.SetStateAction<string | null>>;
  filterRenataState: string | null;
  setFilterRenataState: React.Dispatch<React.SetStateAction<string | null>>;
  filterCategory: string | null;
  setFilterCategory: React.Dispatch<React.SetStateAction<string | null>>;
  filterRange: [Date | null, Date | null];
  setFilterRange: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>;
  fetchingConversations: boolean;
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  assistants: Assistant[];
  channels: Channel[];
  filterAssistant: string | null;
  setFilterAssistant: React.Dispatch<React.SetStateAction<string | null>>;
}

const LeftColumn: React.FC<LeftColumnProps> = ({
  conversations,
  categoryIcons,
  selectedConversationPreview,
  setSelectedConversationPreview,
  handleShowModalCarga,
  handleShowModalMensaje,
  exportToExcel,
  gojimx_businessId,
  gojimx_token,
  filterFecha,
  setFilterFecha,
  filterEstado,
  setFilterEstado,
  filterRenataState,
  setFilterRenataState,
  filterCategory,
  setFilterCategory,
  filterRange,
  setFilterRange,
  fetchingConversations,
  selectedIds,
  setSelectedIds,
  channels,
  assistants,
  filterAssistant,
  setFilterAssistant
}) => {
  // State variables specific to LeftColumn
  const [searchTerm, setSearchTerm] = useState('');
  const selectedConversations = conversations.filter(conversation => selectedIds.includes(conversation.id));
  const conversationListRef = useRef<HTMLDivElement>(null);

  // Function to handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Function to handle selecting a conversation
  const handleSelectConversation = (id: string) => {
    setSelectedIds(prevSelectedIds => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter(selectedId => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  // Function to select or deselect all conversations
  const selectAll = () => {
    if (filteredConversations.length === selectedIds.length) {
      setSelectedIds([]);
    } else {
      const allIds = filteredConversations.map(conversation => conversation.id);
      setSelectedIds(allIds);
    }
  };

  // Function to determine if a conversation is active
  const isActive = (fechaStr: string): boolean => {
    const fecha = new Date(fechaStr);
    const fechaActual = new Date();
    const diferencia = fechaActual.getTime() - fecha.getTime();
    const horas = diferencia / (1000 * 60 * 60);
    return horas <= 24;
  };

  // Filtering conversations based on search term and filters
  const now = new Date();
  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - now.getDay() + 1);
  startOfWeek.setHours(0, 0, 0, 0);

  const filteredConversations = conversations.filter(conversation => {
    const matchesSearchTerm =
      conversation.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      conversation.user_phone.includes(searchTerm);

    let matchesEstadoFilter = true;
    if (filterEstado) {
      if (filterEstado === 'Activas') {
        matchesEstadoFilter = isActive(conversation.last_message_date);
      } else if (filterEstado === 'Inactivas') {
        matchesEstadoFilter = !isActive(conversation.last_message_date);
      }
    }

    const conversationDate = new Date(conversation.last_message_date);
    let matchesDateFilter = true;

    if (filterFecha === 'hoy') {
      matchesDateFilter = conversationDate.toDateString() === now.toDateString();
    } else if (filterFecha === 'esta semana') {
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      matchesDateFilter = conversationDate >= startOfWeek && conversationDate <= endOfWeek;
    } else if (filterFecha === 'este mes') {
      matchesDateFilter = conversationDate.getMonth() === now.getMonth() && conversationDate.getFullYear() === now.getFullYear();
    } else if (filterFecha === 'este año') {
      matchesDateFilter = conversationDate.getFullYear() === now.getFullYear();
    } else if (filterFecha === 'rango' && filterRange[0] && filterRange[1]) {
      matchesDateFilter = conversationDate >= filterRange[0] && conversationDate <= filterRange[1];
    }

    let matchesRenataState = true;
    if (filterRenataState === 'renata_sleep') {
      matchesRenataState = conversation.renata_sleep === true;
    } else if (filterRenataState === 'renata_on') {
      matchesRenataState = conversation.renata_sleep !== true;
    }

    let matchesCategory = true;
    if (filterCategory != null) {
      matchesCategory = conversation.category === filterCategory;
    }

    let activeConversation = conversation.active_conversation;

    let matchesAssistantFilter = true;
    if (filterAssistant != null) {
      const parts = conversation.id.split('@s.whatsapp.net');
      if (parts.length > 1) {
        const channelUsername = parts[1];
        const channel = channels.find(ch => ch.username === channelUsername);
        matchesAssistantFilter = channel ? (channel.assistant_id === filterAssistant) : false;
      } else {
        matchesAssistantFilter = false;
      }
    }

    return (
      matchesSearchTerm &&
      activeConversation &&
      matchesDateFilter &&
      matchesEstadoFilter &&
      matchesRenataState &&
      matchesCategory &&
      matchesAssistantFilter
    );
  });

  return (
    <div
      className='col-md-4 left-column-container'
      style={{
        height: 'calc(100vh - 80px)',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        overflow: 'hidden',
        paddingTop: '20px',
        paddingLeft: '15px'
      }}
    >
      {/* Header Section */}
      <div
        className="d-flex flex-nowrap justify-content-between align-items-center animate__animated animate__fadeIn"
        style={{ flexShrink: 0 }}
      >
        <h1 className="fw-bold text-dark mt-0 left-column-title text-nowrap">
          Mis Conversaciones
        </h1>
      </div>
  
      {/* Fila de búsqueda */}
      <div className="row animate__animated animate__fadeIn mb-3 mt-2">
        <div className="col-12">
          <input
            type="text"
            className="form-control bg-white search-input"
            placeholder="Buscar..."
            onChange={handleSearchChange}
            style={{ paddingInline: '1rem' }}
          />
        </div>
      </div>
  
      {/* Advanced Filters */}
      <div className='row animate__animated animate__fadeIn d-flex' style={{ flexShrink: 0 }}>
        <AdvancedFilters
          selectedFecha={filterFecha}
          setSelectedFecha={setFilterFecha}
          selectedEstado={filterEstado}
          setSelectedEstado={setFilterEstado}
          selectedRenataState={filterRenataState}
          setSelectedRenataState={setFilterRenataState}
          selectedCategory={filterCategory}
          setSelectedCategory={setFilterCategory}
          selectedRange={filterRange}
          setSelectedRange={setFilterRange}
          selectedAssistant={filterAssistant}
          setSelectedAssistant={setFilterAssistant}
          channels={channels}
          assistants={assistants}
        />
      </div>
  
      {/* Conversation Section */}
      <div
        className="bg-gray rounded mt-3 shadow-sm"
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          position: 'relative',
          overflow: 'hidden',
          paddingInline: '10px'
        }}
        
      >
        {/* Conversation Header */}
        <div style={{ flexShrink: 0 }}>
          <div className='row px-2 animate__animated animate__fadeIn mt-2'>
            <div className=" d-flex justify-content-end">
              <div
                onClick={selectAll}
                title="Seleccionar todo"
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              >
                <span className="me-1 select-all-text">
                  Seleccionar todo
                </span>
                {filteredConversations.length === selectedIds.length ? (
                  <i className="bi bi-check-circle-fill icon-small"></i>
                ) : (
                  <i className="bi bi-check-circle icon-small"></i>
                )}
              </div>
            </div>
          </div>
  
          {filteredConversations.length === 0 && !fetchingConversations && (
            <div className='d-flex justify-content-center mt-3'>
              <h5 className='fw-bold text-dark mb-0 conversation-item-text'>
                No se han encontrado conversaciones
              </h5>
            </div>
          )}
  
          {filteredConversations.length === 0 && fetchingConversations && (
            <div className='d-flex justify-content-center'>
              <h1 style={{ fontWeight: 'bold' }}>
                <img className='loading' src={loadingGif} alt="Cargando..." style={{ maxWidth: '50px' }} />
              </h1>
            </div>
          )}
  
          {filterCategory != null && filteredConversations.length > 0 && (
            <div className='row w-100 mx-2 p-1 animate__animated animate__fadeIn'>
              <h3 className='fw-bold left-column-title'>
                {categoryIcons[filterCategory]} {filterCategory}
              </h3>
            </div>
          )}
        </div>
  
        {/* Conversation List */}
        <div
          className={`mx-2 p-2 conversation-list ${selectedIds.length !== 0 ? 'with-button' : 'no-button'}`}
          style={{ flexGrow: 1, overflowY: 'auto' }}
        >
          {filteredConversations.length > 0 && filteredConversations.map((conversation_item, index) => (
            <div
              key={index}
              className={`row p-2 mb-2 conversation-item animate__animated animate__fadeIn rounded
                  ${selectedConversationPreview === conversation_item ? 'bg-black' : 'bg-white'} 
                  ${selectedIds.includes(conversation_item.id) ? 'selected-item' : ''}`}
            >
              <div
                onClick={() => setSelectedConversationPreview(conversation_item)}
                className="col-11 d-flex flex-wrap align-items-center"
              >
                <h5 className={`${isActive(conversation_item.last_message_date) ? 'text-success' : 'text-secondary'} mb-0 fw-bolder me-2 conversation-item-text`}>
                  <i className='bi bi-circle-fill' style={{ fontSize: '0.75rem' }}></i>
                </h5>
                <h5 className={`${selectedConversationPreview === conversation_item ? 'text-white' : 'text-black'} fw-bold mb-0 me-2 conversation-item-text`}>
                  {conversation_item.user_name}
                </h5>
                <h5 className={`${selectedConversationPreview === conversation_item ? 'text-white text-secondary' : 'text-secondary'} mb-0 fw-bolder me-2 conversation-item-text`}>
                  {formatPhoneNumber(conversation_item.user_phone)}
                </h5>
                <h5 className='text-secondary mb-0 me-2 fw-bolder conversation-item-text'>
                  {conversation_item.renata_sleep ? "💤" : ""}
                </h5>
                {filterCategory == null && (
                  <h5 className='text-secondary mb-0 fw-bolder conversation-item-text'>
                    {categoryIcons[conversation_item.category]}
                  </h5>
                )}
              </div>
              <div className="col-1" onClick={() => handleSelectConversation(conversation_item.id)}>
                <h4 className="lead mt-1">
                    <i className={
                    selectedIds.includes(conversation_item.id)
                      ? selectedConversationPreview === conversation_item
                      ? 'bi bi-check-circle-fill text-white'
                      : 'bi bi-check-circle-fill text-black'
                      : 'hide bi bi-circle'
                    } style={{ fontSize: '0.75rem' }}></i>
                </h4>
              </div>
            </div>
          ))}
        </div>
  
        <TransitionGroup>
          {selectedIds.length !== 0 && (
            <CSSTransition timeout={300} classNames="fade" unmountOnExit>
              <div
                className="text-end pe-2"
                style={{
                  flexShrink: 0,
                  position: 'absolute',
                  bottom: '10px',
                  right: '0',
                  width: '100%',
                }}
              >
                <div className="btn btn-lg btn-dark px-2 rounded" onClick={handleShowModalMensaje}>
                  <p className="lead py-0 mb-0 message-button-text">
                    <i className="bi bi-whatsapp me-1" style={{ fontSize: '1rem' }}></i> Enviar Mensaje
                  </p>
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default LeftColumn;