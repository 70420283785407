import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Badge } from 'react-bootstrap';

const ChannelSelectionModal = ({
  show,
  onHide,
  channels,
  onConfirm,
  channelNames, // an array of channel names that are currently assigned
}) => {
  // Use an array to allow multiple selections (max two)
  const [selectedChannels, setSelectedChannels] = useState([]);

  // When the modal opens, if there are currently assigned channels (passed as channelNames), pre-select them
  useEffect(() => {
    if (show) {
      // Filter channels whose channel_name is included in the channelNames array
      const preselected = channels.filter((c) =>
        channelNames.includes(c.channel_name)
      );
      setSelectedChannels(preselected);
    }
  }, [show, channels, channelNames]);

  // Toggle the selection of a channel:
  const toggleChannelSelection = (channel) => {
    setSelectedChannels((prev) => {
      const alreadySelected = prev.some(
        (ch) => ch.username === channel.username
      );
      if (alreadySelected) {
        // Remove channel if already selected
        return prev.filter((ch) => ch.username !== channel.username);
      } else {
        // Allow max 2 selections
        if (prev.length < 2) {
          return [...prev, channel];
        } else {
          return prev;
        }
      }
    });
  };

  // On confirm, call onConfirm for each selected channel
  const handleConfirm = () => {
    selectedChannels.forEach((channel) => {
      onConfirm(channel.assistant_id, channel.username);
    });
  };

  // Helper to check if a channel is selected
  const isSelected = (channel) =>
    selectedChannels.some((ch) => ch.username === channel.username);

  return (
    <Modal show={show} onHide={onHide} centered className="channel-modal">
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">
          <i className="fas fa-comments mr-2"></i>
          Selección de Canal
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="text-muted mb-3 text-center">
          Selecciona hasta 2 canales para asignar el asistente virtual:
        </p>

        <div className="current-channel mb-4 text-center">
          {selectedChannels.length > 0 ? (
            selectedChannels.map((channel) => (
              <Badge
                key={channel.username}
                pill
                bg="info"
                className="px-3 py-2 m-1"
              >
                <i className="fas fa-check-circle mr-2"></i>
                {channel.channel_name} ({channel.username})
              </Badge>
            ))
          ) : (
            <Badge pill bg="info" className="px-3 py-2">
              <i className="fas fa-check-circle mr-2"></i>
              Ninguno seleccionado
            </Badge>
          )}
        </div>

        <div className="channel-list">
          {channels.map((channel, index) => {
            const selected = isSelected(channel);
            return (
              <div
                key={index}
                className={`channel-item p-3 mb-2 rounded ${
                  selected ? 'bg-primary text-white' : 'border hover-cursor'
                }`}
                onClick={() => toggleChannelSelection(channel)}
              >
                <Form.Check
                  type="checkbox"
                  id={`channel-${index}`}
                  name="channel"
                  label={
                    <>
                      <strong>{channel.channel_name}</strong>
                      <span className="ml-2">({channel.username})</span>
                    </>
                  }
                  checked={selected}
                  onChange={(e) => {
                    e.stopPropagation();
                    toggleChannelSelection(channel);
                  }}
                />
                {selected && (
                  <small className="float-right mt-1">
                    <i className="fas fa-check"></i> Seleccionado
                  </small>
                )}
              </div>
            );
          })}

          {channels.length === 0 && (
            <div className="text-center text-muted py-3">
              No hay canales disponibles
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end">
        <Button variant="light" onClick={onHide}>
          <i className="fas fa-times mr-2"></i>
          Cancelar
        </Button>
        <Button
          variant="dark"
          onClick={handleConfirm}
          disabled={selectedChannels.length === 0}
        >
          <i className="fas fa-check mr-2"></i>
          Confirmar Selección
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChannelSelectionModal;