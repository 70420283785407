import React, { useState } from 'react';
import ChannelCard from '../Cards/ChannelCard';
import Logo from '../../img/renata-settings.png';
import WhatsAppLogo from '../../img/WhatsApp_Logo.png';
import InstagramLogo from '../../img/Instagram_Logo.png';
import LinkedinLogo from '../../img/Linkedin_Logo.png';
import AddChannelModal from '../Modals/AddChannelModal';
import XLogo from '../../img/X_Logo.png';
import MessengerLogo from '../../img/Messenger_Logo.png';
import ChannelOptionsModal from '../Modals/ChannelOptionsModal';
import SupportChatModal from '../Home/SupportChatModal';

interface Assistant {
  assistant_id: string;
  name: string;
  description: string;
  intelligenceLevel: string;
  responseLength: string;
  workingHoursStart: number;
  workingHoursEnd: number;
  responseSpeed: string;
  instructions: string;
  active: boolean;
}

interface Channel {
  channel_name: string;
  active: boolean;
  username: string;
  main_number: boolean;
  baileys_port: string;
  baileys_status: boolean;
  channel_type: string;
  assistant_id: string;
}

interface ChannelProps {
  assistants: Assistant[];
  channels: Channel[];
  fetchBusiness: () => void;
}

const ChannelComponent: React.FC<ChannelProps> = ({ assistants, channels, fetchBusiness }) => {
  const [showAddChannelModal, setShowAddChannelModal] = useState(false);
  const [supportChatOpen, setSupportChatOpen] = useState(false);
  const [predefinedQuestion, setPredefinedQuestion] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  // New state and function for channel selection / options modal
  const [selectedChannel, setSelectedChannel] = useState<Channel | undefined>(undefined);
  const [showChannelOptionsModal, setShowChannelOptionsModal] = useState(false);

  const gojimx_payment_type = localStorage.getItem('gojimx_payment_type');

  const handleSupportChatOpen = (question: string) => {
    setPredefinedQuestion(question);
    setSupportChatOpen(true);
  };
  const handleSupportChatClose = () => setSupportChatOpen(false);

  const handleAddChannelClick = () => {
    setShowAddChannelModal(true);
  };

  const handleModalAddChannelClose = () => {
    setShowAddChannelModal(false);
  };

  const handleChannelOptionsClose = () => {
    setShowChannelOptionsModal(false);
  };

  // Reintroduced handleCardClick function
  const handleCardClick = (channel: Channel) => {
    setSelectedChannel(channel);
    setShowChannelOptionsModal(true);
  };

  const getChannelLogoAndBackground = (channelName: string) => {
    switch (channelName) {
      case 'WhatsApp':
        return { logo: WhatsAppLogo, background: '#d1ebd8' };
      case 'Instagram':
        return { logo: InstagramLogo, background: '#f9dbc6' };
      case 'LinkedIn':
        return { logo: LinkedinLogo, background: '#cbdbef' };
      case 'Messenger':
        return { logo: MessengerLogo, background: '#c9daed' };
      case 'X':
        return { logo: XLogo, background: '#4d4d4d' };
      default:
        return { logo: '', background: '#ffffff' };
    }
  };

  return (
    <div className="container px-3 animate__animated animate__fadeIn mb-3">
      <div className="row">
        <h5 className="fw-bold text-dark my-0">
          Mis canales{' '}
          <i
            className="bi bi-question-circle"
            onClick={() => handleSupportChatOpen('¿Qué es un canal?')}
          ></i>
        </h5>
        <p className="text-dark mt-0 mb-3">
          Conecta los canales en los que Renata contestará tus mensajes
        </p>
      </div>
      {/* Loop through channels and display each with its assistant */}
      {channels.map((channel, index) => {
        const { logo, background } = getChannelLogoAndBackground(channel.channel_type);
        // Find the assistant associated with this channel
        const channelAssistant = assistants.find(
          (a) => a.assistant_id === channel.assistant_id
        );
        return (
          <div className="row align-items-center mb-4" key={index}>
            {/* Channel Card */}
            <div className="col-12 col-md-4">
              <ChannelCard
                channel_name={channel.channel_name}
                icon=""
                text={channel.username}
                lineClass={`line${index + 1}`}
                background={background}
                logo={logo}
                onClick={() => handleCardClick(channel)}
                paddingClass="p-4"
                main_number={channel.main_number}
                baileys_status={channel.baileys_status}
                is_add_button={false}
              />
            </div>
            {/* Arrow Column */}
            <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
              <i className="bi bi-arrow-right" style={{ fontSize: '2rem', color: 'black' }}></i>
            </div>
            {/* Assistant Card for this Channel */}
            <div className="col-12 col-md-7">
              {channelAssistant ? (
                <div
                  className="btn bg-light p-3 mb-3 w-100 d-flex align-items-center rounded-5"
                  style={{ minHeight: '150px' }}
                >
                  <img
                    style={{ width: '20%' }}
                    className="me-2"
                    src={Logo}
                    alt="Assistant Logo"
                  />
                  <div className="text-start">
                    <h4>{channelAssistant.name}</h4>
                    <small>{channelAssistant.description}</small>
                  </div>
                </div>
              ) : (
                <div
                  className="btn bg-light p-3 mb-3 w-100 d-flex align-items-center rounded-5"
                  style={{ minHeight: '150px' }}
                >
                  <div className="text-start">
                    <h4>Assistant no encontrado</h4>
                    <small>No hay datos para este canal</small>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
      {/* Add Channel Card (if allowed) */}
      {((gojimx_payment_type === 'Premium' && channels.length < 6) ||
        (gojimx_payment_type !== 'Premium' && channels.length < 3)) && (
        <div className="row align-items-center mb-4">
          <div className="col-12 col-md-4">
            <ChannelCard
              channel_name=""
              icon="➕"
              text=""
              lineClass={`line${channels.length + 1}`}
              background="#e7e7e7"
              logo=""
              onClick={handleAddChannelClick}
              paddingClass="p-1"
              main_number={false}
              baileys_status={false}
              is_add_button={true}
            />
          </div>
          {/* Leave arrow and assistant columns empty for the add channel card */}
          <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
            {/* Optionally, you could include a placeholder arrow */}
          </div>
          <div className="col-12 col-md-7"></div>
        </div>
      )}
      <AddChannelModal
        show={showAddChannelModal}
        onHide={handleModalAddChannelClose}
        isLoading={isLoading}
        fetchBusiness={fetchBusiness}
      />
      <ChannelOptionsModal
        show={showChannelOptionsModal}
        onHide={handleChannelOptionsClose}
        isLoading={false}
        fetchBusiness={fetchBusiness}
        channel={selectedChannel}
      />
      <SupportChatModal
        show={supportChatOpen}
        handleClose={handleSupportChatClose}
        predefinedQuestion={predefinedQuestion}
      />
    </div>
  );
};

export default ChannelComponent;