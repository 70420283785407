import React, { useState, ChangeEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import Logo from '../../img/renata-logo.svg';
import loadingGif from '../../img/loading.gif';
import config from '../../config/config';
import DiscountCodeModal from '../Modals/DiscountCodeModal';
import ConfirmationModal from '../Modals/ConfirmationModal';
import '../../css/login.css';
import '../../css/modal.css';

interface OptionType {
  value: string;
  label: string;
}

const caseUseOptions: OptionType[] = [
  { value: "Atención al Cliente", label: "Atención al Cliente" },
  { value: "Gestión de Citas y Reservas", label: "Gestión de Citas y Reservas" },
  { value: "Soporte Técnico", label: "Soporte Técnico" },
  { value: "Encuestas y Feedback", label: "Encuestas y Feedback" },
  { value: "Promociones y Ofertas Especiales", label: "Promociones y Ofertas Especiales" },
  { value: "Información de Productos y Servicios", label: "Información de Productos y Servicios" },
  { value: "Asistencia en Compras", label: "Asistencia en Compras" },
  { value: "Confirmaciones y Seguimientos de Pedidos", label: "Confirmaciones y Seguimientos de Pedidos" },
  { value: "Resolución de Quejas y Reclamaciones", label: "Resolución de Quejas y Reclamaciones" },
  { value: "Información de Horarios y Disponibilidad", label: "Información de Horarios y Disponibilidad" },
];

const animatedComponents = makeAnimated();

function Register() {
  const navigate = useNavigate();

  // Stepper state
  const [activeStep, setActiveStep] = useState(0);

  // Step 1: User Info
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);

  // Validation states for step 1
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  // Step 2: Business Info
  const [businessName, setBusinessName] = useState('');
  const [selectedCase, setSelectedCase] = useState<OptionType[]>([]);
  const [businessNameError, setBusinessNameError] = useState(false);
  const [usecaseError, setUsecaseError] = useState(false);

  // Step 3: Payment Selection
  const [paymentType, setPaymentType] = useState("");
  const [renataStandardSelected, setRenataStandardSelected] = useState(false);
  const [renataPremiumSelected, setRenataPremiumSelected] = useState(false);
  const [renataCouponSelected, setRenataCouponSelected] = useState(false);
  const [renataGratisSelected, setRenataGratisSelected] = useState(false);

  // Other states
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [discountCode, setDiscountCode] = useState<string | undefined>(undefined);
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ----- STEP 1 Handlers -----
  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    setUsernameError(false);
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(false);
  };
  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, '');
    // Format phone number based on prefix
    const prefix = value.slice(0, 2);
    if (prefix === '55' || prefix === '56' || prefix === '33' || prefix === '81') {
      if (value.length > 2 && value.length <= 6) {
        value = value.slice(0, 2) + ' ' + value.slice(2);
      } else if (value.length > 6) {
        value = value.slice(0, 2) + ' ' + value.slice(2, 6) + ' ' + value.slice(6, 10);
      }
    } else {
      if (value.length > 3 && value.length <= 6) {
        value = value.slice(0, 3) + ' ' + value.slice(3);
      } else if (value.length > 6) {
        value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6, 10);
      }
    }
    setPhoneNumber(value);
    setPhoneError(false);
  };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError(false);
  };
  const handleTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsTermsChecked(e.target.checked);
  };
  const handlePrivacyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsPrivacyChecked(e.target.checked);
  };

  // ----- STEP 2 Handlers -----
  const handleBusinessNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBusinessName(e.target.value);
    setBusinessNameError(false);
  };
  const handleCaseUseChange = (selectedOption: any) => {
    setSelectedCase(selectedOption);
    setUsecaseError(selectedOption.length === 0);
  };

  // ----- Payment Selection Handler -----
  const toggleRenataSelection = (type: string) => {
    switch (type) {
      case "gratis":
        setPaymentType("Gratis");
        setRenataGratisSelected(true);
        setRenataStandardSelected(false);
        setRenataCouponSelected(false);
        setRenataPremiumSelected(false);
        break;
      case "Standard":
        setPaymentType("Standard");
        setRenataStandardSelected(true);
        setRenataGratisSelected(false);
        setRenataCouponSelected(false);
        setRenataPremiumSelected(false);
        break;
      case "premium":
        setPaymentType("Premium");
        setRenataPremiumSelected(true);
        setRenataStandardSelected(false);
        setRenataCouponSelected(false);
        setRenataGratisSelected(false);
        break;
      case "coupon":
        setPaymentType("Coupon");
        setRenataCouponSelected(true);
        setRenataGratisSelected(false);
        setRenataPremiumSelected(false);
        setRenataStandardSelected(false);
        break;
      default:
        break;
    }
  };

  // ----- Stepper Navigation -----
  const handleNext = () => {
    // Validate current step fields
    if (activeStep === 0) {
      let valid = true;
      if (!username.trim()) {
        setUsernameError(true);
        valid = false;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError(true);
        valid = false;
      }
      // Check that phone (without spaces) has 10–11 digits (adjust as needed)
      const phoneDigits = phoneNumber.replace(/\s/g, '');
      if (!(phoneDigits.length === 10 || phoneDigits.length === 11)) {
        setPhoneError(true);
        valid = false;
      }
      if (password.length < 5) {
        setPasswordError(true);
        valid = false;
      }
      if (!isTermsChecked || !isPrivacyChecked) {
        alert('Debes aceptar los términos y condiciones y haber leído el aviso de privacidad.');
        valid = false;
      }
      if (!valid) return;
    }
    if (activeStep === 1) {
      let valid = true;
      if (!businessName.trim()) {
        setBusinessNameError(true);
        valid = false;
      }
      if (selectedCase.length === 0) {
        setUsecaseError(true);
        valid = false;
      }
      if (!valid) return;
    }
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  // ----- API Call for Registration -----
  const registerUser = async () => {
    try {
      const response = await axios.post(`${config.backendURL}/register`, {
        username,
        email,
        phoneNumber,
        password,
        businessName,
        selectedCase,
        paymentType,
        coupon: discountCode,
      });
      if (response.status === 201) {
        // Store session data
        localStorage.setItem('gojimx_token', response.data.token);
        localStorage.setItem('gojimx_phone', response.data.phoneNumber);
        localStorage.setItem('gojimx_username', response.data.username);
        localStorage.setItem('gojimx_businessId', response.data.businessId);
        localStorage.setItem('gojimx_business_name', response.data.business_name);
        localStorage.setItem('gojimx_assistant_id', response.data.assistant_id);
        localStorage.setItem('gojimx_baileys_status', response.data.baileys_status);
        localStorage.setItem('gojimx_baileys_port', response.data.baileys_port);
        localStorage.setItem('gojimx_needs_payment', paymentType === 'Coupon' ? 'false' : 'true');
        localStorage.setItem('gojimx_payment_type', paymentType);
        localStorage.setItem('gojimx_email', response.data.email);

        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('gojimx_token')}`;
        window.location.reload();
      }
    } catch (error: any) {
      console.error('Error al registrar el usuario', error);
      setLoginError('Error al registrar el usuario. Intenta nuevamente.');
    } finally {
      setShowDiscountModal(false);
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    // If the coupon option is selected, show the discount modal first
    if (paymentType === 'Coupon') {
      setShowDiscountModal(true);
      setIsLoading(false);
      return;
    }
    await registerUser();
    setIsLoading(false);
  };

  const handleApplyCoupon = async (coupon: string) => {
    try {
      setLoadingCoupon(true);
      const response = await axios.post(`${config.backendURL}/validate_coupon`, { coupon });
      if (response.status === 200) {
        await registerUser();
        setTimeout(() => setIsLoading(false), 1000);
      }
    } catch (error: any) {
      setShowDiscountModal(false);
      setModalMessage(`El cupón "${coupon}" no es válido o ya expiró.`);
      setIsModalOpen(true);
      setDiscountCode(undefined);
      setLoadingCoupon(false);
    }
  };

  return (
    <div className="container-login-outer">
      <div className="register-container">
        <div className="background-login">
          <svg
            viewBox="0 0 1280 832"
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M640 585.096L419.496 359.9C263.529 200.616 0 313.214 0 538.409V832.262H1280V-68.5209L640 585.096Z"
              fill="#D6E1DF"
            />
          </svg>
        </div>
        {/* <Box sx={{ textAlign: 'center', mb: 4 }}>
          <img src={Logo} alt="Renata Logo" style={{ width: '50%' }} />
        </Box> */}
        <div className="animate__animated animate__jackInTheBox login-box px-custom-login">
          {/* Back navigation */}
          <IconButton
            onClick={() => navigate('/login')}
            sx={{ position: 'absolute', top: 16, left: 16, color: '#000' }}
            aria-label="Volver"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" className="font-light title-login mb-4" align="center">
            Regístrate
          </Typography>
          <form noValidate onSubmit={handleSubmit}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {/* Step 1: Datos Personales */}
              <Step key="Datos Personales">
                <StepLabel>Datos Personales</StepLabel>
                <StepContent>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Nombre Completo"
                    className="form-control rounded centered-placeholder mb-3"
                    value={username}
                    onChange={handleUsernameChange}
                    error={usernameError}
                    helperText={usernameError ? 'Introduce un nombre' : ''}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Correo Electrónico"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    error={emailError}
                    helperText={emailError ? 'Correo inválido' : ''}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Número de Teléfono"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    error={phoneError}
                    helperText={phoneError ? 'Número inválido' : ''}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Contraseña"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    error={passwordError}
                    helperText={passwordError ? 'Mínimo 5 caracteres' : ''}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={isTermsChecked} onChange={handleTermsChange} />}
                    label={
                      <span style={{ fontSize: '0.9rem' }}>
                        Acepto los <a href="https://goji.mx/terms" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>
                      </span>
                    }
                  />
                  <FormControlLabel
                    control={<Checkbox checked={isPrivacyChecked} onChange={handlePrivacyChange} />}
                    label={
                      <span style={{ fontSize: '0.9rem' }}>
                        Acepto haber leído el <a href="https://goji.mx/privacy" target="_blank" rel="noopener noreferrer">Aviso de Privacidad</a>
                      </span>
                    }
                  />
                  <Box sx={{ mt: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      Continuar <i className="bi bi-arrow-right"></i>
                    </Button>
                  </Box>
                </StepContent>
              </Step>
              {/* Step 2: Información de la Organización */}
              <Step key="Información de la Organización">
                <StepLabel>Información de la Organización</StepLabel>
                <StepContent>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Nombre del Negocio"
                    className="form-control rounded centered-placeholder mb-3"
                    value={businessName}
                    onChange={handleBusinessNameChange}
                    error={businessNameError}
                    helperText={businessNameError ? 'Introduce un nombre de negocio' : ''}
                  />
                  <Box sx={{ mt: 1 }}>
                    <ReactSelect
                      closeMenuOnSelect={true}
                      placeholder={'Caso de Uso'}
                      components={animatedComponents}
                      isMulti={true}
                      onChange={handleCaseUseChange}
                      options={caseUseOptions}
                      menuPortalTarget={document.body}
                      className="form-control rounded centered-placeholder mb-3"

                      styles={{
                        control: (provided: any) => ({
                          ...provided,
                          align: 'center',
                          borderWidth: '0px'
                        }),
                        menuPortal: (base: any) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        placeholder: (provided: any) => ({
                          ...provided,
                          color: '#56595c',
                          textAlign: 'center',
                        }),
                      }}
                    />
                    {usecaseError && (
                      <Typography variant="body2" color="error">
                        Selecciona al menos una opción
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      Continuar <i className="bi bi-arrow-right"></i>
                    </Button>
                    <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Atrás
                    </Button>
                  </Box>
                </StepContent>
              </Step>
              {/* Step 3: Pago */}
              <Step key="Pago">
                <StepLabel>Pago</StepLabel>
                <StepContent>
                  <Typography variant="h6" gutterBottom>
                    Selecciona tu plan
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      flexWrap: 'wrap',
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: '12px',
                        border: renataStandardSelected ? '2px solid #000' : '1px solid #ccc',
                        cursor: 'pointer',
                        mb: 2,
                        width: 200,
                      }}
                      onClick={() => toggleRenataSelection("Standard")}
                    >
                      <Typography variant="h6" align="center">
                        🙋🏻‍♀️ Standard
                      </Typography>
                      <Typography variant="subtitle1" align="center">
                        $990/mes*
                      </Typography>
                      <Typography variant="body2" align="left">
                        • 3 Números de WhatsApp<br />
                        • 3 Asistentes Chat<br />
                        • 3 Accesos Renata-APP<br />
                        • Contesta 24/7<br />
                        • 55,000 puntos
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: '12px',
                        border: renataPremiumSelected ? '2px solid #000' : '1px solid #ccc',
                        cursor: 'pointer',
                        mb: 2,
                        width: 200,
                      }}
                      onClick={() => toggleRenataSelection("premium")}
                    >
                      <Typography variant="h6" align="center">
                        ⭐ Premium
                      </Typography>
                      <Typography variant="subtitle1" align="center">
                        $2990/mes*
                      </Typography>
                      <Typography variant="body2" align="left">
                        • 6 Números de WhatsApp<br />
                        • 6 Asistentes Chat y Llamada<br />
                        • 6 Accesos Renata-APP<br />
                        • Contesta 24/7<br />
                        • Soporte Técnico<br />
                        • 180,000 puntos
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: '12px',
                        border: renataCouponSelected ? '2px solid #000' : '1px solid #ccc',
                        cursor: 'pointer',
                        mb: 2,
                        width: 200,
                      }}
                      onClick={() => toggleRenataSelection("coupon")}
                    >
                      <Typography variant="h6" align="center">
                        🎟️ Cupón
                      </Typography>
                      <Typography variant="body2" align="center">
                        Si tienes un cupón, selecciona esta opción
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    {(renataStandardSelected ||
                      renataPremiumSelected ||
                      renataGratisSelected ||
                      renataCouponSelected) && (
                      <>
                        <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
                          {isLoading ? (
                            <img className="loading" src={loadingGif} alt="Cargando..." />
                          ) : (
                            'Proceder al Pago'
                          )}
                        </Button>
                        <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                          Atrás
                        </Button>
                      </>
                    )}
                  </Box>
                </StepContent>
              </Step>
            </Stepper>
            {activeStep === 3 && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>Registro completado</Typography>
                <Button onClick={() => setActiveStep(0)} sx={{ mt: 1, mr: 1 }}>
                  Reiniciar
                </Button>
              </Paper>
            )}
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Typography variant="body2">
                ¿Ya tienes una cuenta? <Link to="/login" className="text-primary">Inicia sesión</Link>
              </Typography>
            </Box>
          </form>
          <DiscountCodeModal
            show={showDiscountModal}
            handleClose={() => setShowDiscountModal(false)}
            handleApplyCoupon={handleApplyCoupon}
            codeError={false}
            errorMessage={''}
            setCodeError={() => {}}
            setErrorMessage={() => {}}
            discountCode={discountCode}
            setDiscountCode={setDiscountCode}
            isLoading={loadingCoupon}
          />
          <ConfirmationModal
            show={isModalOpen}
            type="Cupón"
            titleText="Inválido"
            onHide={() => setIsModalOpen(false)}
            text={modalMessage}
          />
        </div>
      </div>
    </div>
  );
}

export default Register;