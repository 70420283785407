import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config/config';
import loadingGif from '../../img/loading.gif';
import renata_img from '../../img/renata.png';
import user_img from '../../img/user.png';
import ConfirmationModal from './ConfirmationModal';
import { formatPhoneNumber } from '../../functions/functions';
import Logo from '../../img/renata-settings.png';

interface Conversation {
  category: string;
  id: string;
  threadId: string;
  business_id: string;
  summary: string;
  user_name: string;
  user_phone: string;
  renata_sleep: boolean;
  active_conversation: boolean;
  last_message_date: string;
}

interface Message {
  id: string;
  object: string;
  created_at: number;
  assistant_id: string | null;
  thread_id: string;
  run_id: string | null;
  role: string;
  content: {
    type: string;
    text: {
      value: string;
      annotations: any[];
    };
  }[];
  file_ids: any[];
  metadata: Record<string, unknown>;
}

interface ConversationChatModalProps {
  show: boolean;
  onHide: () => void;
  threadId: string;
  user_name: string;
  user_phone: string;
  conversationId: string;
  summary: string;
  category: string;
}

export interface Assistant {
  assistant_id: string;
  name: string;
  description: string;
  intelligenceLevel: string;
  responseLength: string;
  workingHoursStart: number;
  workingHoursEnd: number;
  responseSpeed: string;
  instructions: string;
  active: boolean;
  active_skills: any[];
  assistant_knowledge_base: string;
  whitelist: any[];
  _id: string;
  voice?: string;
}

export interface Channel {
  channel_name: string;
  active: boolean;
  username: string;
  baileys_port: string;
  baileys_status: boolean;
  main_number: boolean;
  channel_type: string;
  _id: string;
  assistant_id: string;
}

const ConversationChatModal: React.FC<ConversationChatModalProps> = ({
  show,
  onHide,
  threadId,
  user_name,
  user_phone,
  conversationId,
  summary,
  category
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [fetchingMessages, setFetchingMessages] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [confirmModalType, setConfirmModalType] = useState('✅');
  const [newMessage, setNewMessage] = useState("");
  const conversationBoxRef = useRef<HTMLDivElement>(null);

  const gojimx_token = localStorage.getItem('gojimx_token');
  const gojimx_businessId = localStorage.getItem('gojimx_businessId');

  // RightColumn specific states
  const [renata_sleep, setRenataSleep] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [secondNumber, setSecondNumber] = useState<string | null>(null);
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [categoryIcons, setCategoryIcons] = useState<{ [key: string]: any }>({});
  
  // Mock conversation object for RightColumn functionality
  const [selectedConversationPreview, setSelectedConversationPreview] = useState<Conversation>({
    id: conversationId,
    threadId: threadId,
    user_name: user_name,
    user_phone: user_phone,
    summary: '',
    category: '',
    renata_sleep: false,
    business_id: '',
    active_conversation: true,
    last_message_date: '',
  });

  // Helper function to convert timestamp to date
  function convertirAFecha(fechaEnSegundos: number) {
    const fechaEnMilisegundos = fechaEnSegundos * 1000;
    const fecha = new Date(fechaEnMilisegundos);
    return fecha.toLocaleString();
  }

  // Helper function to remove brackets from message text
  function eliminarCorchetes(text: string) {
    if (text.startsWith('[RENATA MESSAGE]')) {
      return text;
    }
    const regex = /\[.*?\]/g;
    return text.replace(regex, '');
  }

  const scrollToBottom = () => {
    if (conversationBoxRef.current) {
      conversationBoxRef.current.scrollTo({
        top: conversationBoxRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  // Fetch assistant and channel information
  const fetchBusinessStatus = async () => {
    try {
      const response = await axios.get(`${config.backendURL}/get_business_status`, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`,
        },
      });
      
      if (response.data.status === 'success') {
        setAssistants(response.data.assistants || []);
        setChannels(response.data.channels || []);
      }
    } catch (error) {
      console.error('Error fetching business status:', error);
    }
  };

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${config.backendURL}/get_category/${gojimx_businessId}`,
        {
          headers: {
            Authorization: `Bearer ${gojimx_token}`,
          },
        }
      );
      if (response.data[0] && response.data[0][0] && response.data[0][0].options) {
        const options = response.data[0][0].options;
        const iconsMapping = options.reduce(
          (acc: { [key: string]: any }, option: { option_name: string; icon: any }) => {
            acc[option.option_name] = option.icon;
            return acc;
          },
          {}
        );
        setCategoryIcons(iconsMapping);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Fetch message summary and conversation details
  const fetchConversationDetails = async () => {
    if (!threadId) return;
    
    try {
      const response = await axios.get(`${config.backendURL}/get_conversation/${threadId}`, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`,
        },
      });
      
      if (response.data.conversation) {
        const conversation = response.data.conversation;
        setSelectedConversationPreview(prev => ({
          ...prev,
          category: conversation.category || '',
          renata_sleep: conversation.renata_sleep || false
        }));
      }
    } catch (error) {
      console.error('Error fetching conversation details:', error);
    }
  };

  useEffect(() => { 
    if (selectedConversationPreview) {
      console.log(selectedConversationPreview);
    }
  }
  , [selectedConversationPreview]);

  // Extract second number from conversation ID
  useEffect(() => {
    if (conversationId) {
      const parts = conversationId.split('@s.whatsapp.net');
      if (parts.length > 1) {
        setSecondNumber(parts[1]);
      } else {
        setSecondNumber(null);
      }
    }
  }, [conversationId]);

  // Load initial data
  useEffect(() => {
    if (show) {
      fetchBusinessStatus();
      fetchCategories();
      fetchConversationDetails();
    }
  }, [show]);

  // Fetch messages function
  const fetchMessages = async (firstTime: boolean) => {
    if (!threadId) return;
    if (firstTime) setFetchingMessages(true);

    const url = `${config.backendURL}/get_messages/${threadId}`;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${gojimx_token}`,
      },
    });

    if (!response.ok) {
      setFetchingMessages(false);
      console.error("Network response was not ok");
      return;
    }

    const data = await response.json();
    const fetchedMessages = data.data.reverse();

    if (firstTime) {
      // For the first fetch, just set the messages
      setMessages(fetchedMessages);
      setTimeout(scrollToBottom, 200);
      if (data.renata_sleep) {
        setRenataSleep(data.renata_sleep);
      }
    } else {
      // Append only new messages
      setMessages((prevMessages: Message[]) => {
        const existingMessageIds = new Set(prevMessages.map((msg: Message) => msg.id));
        const newMessages = fetchedMessages.filter((msg: Message) => !existingMessageIds.has(msg.id));
        return newMessages.length > 0 ? [...prevMessages, ...newMessages] : prevMessages;
      });
    }
    
    setFetchingMessages(false);
  };

  // Handle sending a message
  const handleSendMessage = async () => {
    if (!threadId) return;

    // Define the URL for the POST request
    const url = `${config.backendURL}/addMessage`;

    // Prepare the data for the POST request
    const requestBody = {
      param_threadId: threadId,
      business_id: gojimx_businessId,
      whatsAppNumber: user_phone,
      messageText: newMessage.trim(),
      channel: conversationId
    };

    // Clear the newMessage state to reset the input field
    setNewMessage('');

    try {
      // Make the POST request using axios
      const response = await axios.post(url, requestBody, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`,
          'Content-Type': 'application/json',
        },
      });

      console.log('Message sent successfully:', response.data);
      setConfirmModalType('✅');
      setModalMessage('Mensaje enviado correctamente.');
      setIsModalOpen(true);

      // Fetch messages again to update the UI
      fetchMessages(false);

      // Scroll to the bottom of the conversation
      scrollToBottom();
    } catch (error) {
      // Log or handle errors
      setConfirmModalType('❌');
      setModalMessage('Error al mandar el mensaje. Verifique en la configuración que el canal de WhatsApp se encuentre conectado correctamente.');
      setIsModalOpen(true);
      console.error('Error sending message:', error);
    }
  };

  // Handle Enter key press to send message
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValue = (e.target as HTMLInputElement).value;
    const regex = /[a-zA-Z]/; // Regular expression to identify a letter char
    if (e.key === 'Enter' && regex.test(inputValue)) {
      e.preventDefault(); // Prevent the default behavior of Enter
      handleSendMessage();
    }
  };

  // Handle Renata sleep toggle
  async function handleRenataSleepChange(e: { target: { checked: any } }) {
    const newRenataSleepStatus = !e.target.checked;
    
    const url = `${config.backendURL}/updateRenataSleep`;
    const requestBody = {
      threadId: threadId,
      renata_sleep: newRenataSleepStatus,
    };

    try {
      await axios.post(url, requestBody, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`,
          'Content-Type': 'application/json',
        },
      });
      
      setConfirmModalType('✅');
      setModalMessage(`Renata ha sido ${newRenataSleepStatus ? 'apagada' : 'encendida'} en esta conversación.`);
      setIsModalOpen(true);
      setRenataSleep(newRenataSleepStatus);
      
      // Update the local state
      setSelectedConversationPreview(prev => ({
        ...prev,
        renata_sleep: newRenataSleepStatus
      }));
    } catch (error) {
      console.error('Error updating Renata sleep status:', error);
      setConfirmModalType('❌');
      setModalMessage('Failed to update Renata sleep status. Please try again.');
      setIsModalOpen(true);
    }
  }

  // Handle conversation reset
  async function handleResetConversation() {
    try {
      await axios.post(
        `${config.backendURL}/reset-conversation`,
        { id: conversationId },
        {
          headers: {
            Authorization: `Bearer ${gojimx_token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setConfirmModalType('✅');
      setModalMessage('La conversación ha sido reiniciada.');
      setIsModalOpen(true);
      setMessages([]);
    } catch (error) {
      console.error('Error reiniciando la conversación:', error);
      setConfirmModalType('❌');
      setModalMessage('Error al reiniciar la conversación. Por favor, intente nuevamente.');
      setIsModalOpen(true);
    }
    setShowResetModal(false);
  }

  // Find matching channel and assistant
  const matchingChannel = channels.find(channel => channel.username === secondNumber);
  const matchingAssistant = assistants.find(
    assistant => assistant.assistant_id === matchingChannel?.assistant_id
  );

  // Fetch messages when modal is opened and set polling interval
  useEffect(() => {
    if (show && threadId) {
      // Initial fetch
      fetchMessages(true);
      
      // Set up polling interval (every 3 seconds)
      const intervalId = setInterval(() => {
        fetchMessages(false);
      }, 3000);
      
      // Clear interval when component unmounts or modal closes
      return () => clearInterval(intervalId);
    }
  }, [show, threadId]);

  // Scroll to bottom when new messages are added
  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered >
       
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="row w-100 h-100 mx-0">
            <div className="">
              <div className="bg-gray p-3 rounded shadow-sm">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 className="fw-bold text-dark mb-1 text-truncate">
                      {user_name}
                    </h5>
                  </div>
                  <div className="d-flex align-items-center">
                    <a
                      className="text-danger mx-2 d-flex align-items-center"
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        textDecorationLine: "underline",
                      }}
                      onClick={() => setShowResetModal(true)}
                    >
                      <i className="bi bi-eraser me-1"></i>
                      Limpiar conversación
                    </a>
                    <button
                      className="btn me-2"
                      onClick={() => {
                        const link =
                          config.frontURL +
                          "/messages?thread=" +
                          String(threadId) +
                          "&name=" +
                          user_name +
                          "&id=" +
                          conversationId +
                          "&phone=" +
                          user_phone +
                          "&summary=" +
                          selectedConversationPreview?.summary +
                          "&categoryName=" +
                          selectedConversationPreview?.category +
                          (categoryIcons && selectedConversationPreview?.category
                            ? "&icon=" +
                              categoryIcons[selectedConversationPreview?.category]
                            : "");

                        navigator.clipboard
                          .writeText(link)
                          .then(() => {
                            setModalMessage("Se copió el link para compartir la conversación");
                            setIsModalOpen(true);
                          })
                          .catch((err) => {
                            setModalMessage("No se pudo copiar el link para compartir la conversación");
                            setIsModalOpen(true);
                            console.error("Failed to copy: ", err);
                          });
                      }}
                    >
                      <i className="bi bi-share"></i>
                    </button>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={!renata_sleep}
                        onChange={handleRenataSleepChange}
                      />
                    </div>
                  </div>
                </div>
                <p>{summary}</p>
                <div className="d-flex justify-content-between align-items-center">
                  {category && (
                    <div className="badge bg-warning text-dark d-inline-flex align-items-center">
                      <h4 className="mb-0">
                        {categoryIcons[category]}
                      </h4>
                      <h6 className="mx-2 mb-0 fw-bold text-dark">
                        {category}
                      </h6>
                    </div>
                  )}
                  {matchingAssistant && (
                    <div className="text-dark d-inline-flex align-items-center">
                      <img src={Logo} alt="Assistant Logo" style={{ width: "40px" }} />
                      <h6 className="mx-2 mb-0 fw-bold text-dark">
                        {matchingAssistant.name}
                      </h6>
                    </div>
                  )}
                </div>
              </div>

              {/* Chat Panel */}
              <div
                className='bg-gray p-3 rounded mt-4 shadow-sm'
                style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', height: 'calc(100vh - 350px)' }}
              >
                <div className='d-flex align-items-center mx-3 justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <h5 className='fw-bold text-dark mb-0 me-3'>WhatsApp</h5>
                    <h5 className='fw-bolder mb-0 text-secondary'>{formatPhoneNumber(user_phone)}</h5>
                  </div>
                </div>

                {fetchingMessages ? (
                  <div className='d-flex justify-content-center mt-5'>
                    <img src={loadingGif} alt="Cargando..." style={{ width: '30px' }} />
                  </div>
                ) : (
                  <div
                    className='rounded conversation-box-my-conversations mt-3'
                    style={{ flexGrow: 1, overflow: 'hidden' }}
                  >
                    <div
                      className='conversation-scroll-my-conversations bg-white'
                      ref={conversationBoxRef}
                      style={{ height: '100%', overflowY: 'auto' }}
                    >
                      <div className="fade-top-preview"></div>
                      {messages.map((message) => {
                        const messageText = message.content[0].text.value;
                        const regex = /"mensaje_original_del_usuario":\s*"([^"]*)"/;
                        const match = messageText.match(regex);
                        const displayedMessage = match ? match[1] : messageText;

                        const messageClass =
                          messageText.startsWith('[RENATA MESSAGE]') || message.role !== 'user'
                            ? 'message-item-2'
                            : 'message-item-1';
                        const isRenataMessage = message.role !== 'user' || messageText.startsWith('[RENATA MESSAGE]');

                        return (
                          <div key={message.id} className="row px-3 animate__animated animate__fadeIn">
                            <div className={`d-flex justify-content-${isRenataMessage ? 'end' : 'start'}`}>
                              {!isRenataMessage ? <img src={user_img} className='user_image rounded mx-2' alt="User" /> : null}
                              <p className={`${messageClass} mb-0 p-3`}>{eliminarCorchetes(displayedMessage)}</p>
                              {isRenataMessage ? <img src={renata_img} className='renata_image rounded mx-2' alt="Renata" /> : null}
                            </div>
                            <div className={`d-flex justify-content-${isRenataMessage ? 'end' : 'start'}`}>
                              <p className={`text-secondary pt-0 mt-0 mb-3 ${isRenataMessage ? 'me-date' : 'me-date2'}`}>
                                {(message?.metadata?.audio ? '🎤(Mensaje de voz) ' : '') + convertirAFecha(Number(message.created_at))}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                <div className='mt-3 d-flex'>
                  <div className='row w-100 border border-2 rounded'>
                    <div className="col-10 px-1">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-custom py-2"
                        placeholder="Escribe tu mensaje..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div className="col-2 ps-0 pe-2 py-2">
                      <button
                        className="btn btn-dark w-100"
                        disabled={!newMessage.trim()}
                        onClick={handleSendMessage}
                      >
                        <i className="bi bi-send-fill"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-md-4">
              {/* Additional content or functionality can be added here */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ConfirmationModal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        text={modalMessage}
        type={confirmModalType}
        titleText="Resultado de la Operación"
      />

      {/* Reset Conversation Confirmation Modal */}
      <Modal show={showResetModal} onHide={() => setShowResetModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>Confirmar reinicio de conversación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Estás seguro de que deseas reiniciar esta conversación? Esta acción no se puede deshacer.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowResetModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleResetConversation}>
            Reiniciar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConversationChatModal;