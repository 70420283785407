import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import '../../css/main.css';
import Menu from '../Menu/Menu';
import TopBar from '../../TopBar';
import axios from 'axios';
import Chart, { ChartConfiguration } from 'chart.js/auto';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers';
import { fetchBusinessStatus } from '../../functions/functions';
import { exportToExcelStatistics } from '../../functions/excel';
import config from '../../config/config';
import SupportChatModal from '../Home/SupportChatModal';
import Sidebar from '../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

// Interfaces para tipos
interface Conversation {
  category: string;
  last_message_date: string;
  user_name: string;
  id: string;
  threadId: string;
  business_id: string;
  summary: string;
  user_phone: string;
  renata_sleep: boolean;
}

interface Interaction {
  _id: string;
  interaction_phone: string;
  interaction_date: string;
  thread_id: string;
  inputToken: number;
  outputToken: number;
  userMessages: number;
}

interface Message {
  _id: string;
  message_phone: string;
  message_date: string;
  thread_id: string;
}

interface StatisticsData {
  _id: string;
  business_id: string;
  interactions: Interaction[];
  messages: Message[];
}

function Statistics() {
  // Variables de local storage
  const gojimx_token = localStorage.getItem('gojimx_token');
  const gojimx_businessId = localStorage.getItem('gojimx_businessId');
  const gojimx_payment_type = localStorage.getItem('gojimx_payment_type');

  const navigate = useNavigate();

  // Variables de estado
  const [supportChatOpen, setSupportChatOpen] = useState(false);
  const [predefinedQuestion, setPredefinedQuestion] = useState<string>('');
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState('Today');
  const [conversationsCount, setConversationsCount] = useState(0);
  const [interactionsCount, setInteractionsCount] = useState(0);
  const [totalMessagesCount, setTotalMessagesCount] = useState(0);
  const [statistics, setStatistics] = useState<StatisticsData | null>(null);
  const [conversations, setConversations] = useState<Conversation[] | null>(null);
  const [chartCreated, setChartCreated] = useState(false);
  const [categoryCounts, setCategoryCounts] = useState<{ [key: string]: number }>({});
  const [filteredInteractions, setFilteredInteractions] = useState<Interaction[]>([]);
  const [filteredConversations, setFilteredConversations] = useState<Conversation[]>([]);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [formatLabelSegment, setFormatLabelSegment] = useState('Hoy');
  const [inputToken, setInputToken] = useState(0);
  const [outputToken, setOutputToken] = useState(0);

  const chartContainer = useRef<HTMLCanvasElement>(null);

  // Handlers para modal y chat de soporte
  const handleSupportChatOpen = (question: string) => {
    setPredefinedQuestion(question);
    setSupportChatOpen(true);
  };
  const handleSupportChatClose = () => setSupportChatOpen(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // Handlers para el modal de calendario
  const handleOpenCalendarModal = () => setShowCalendarModal(true);
  const handleCloseCalendarModal = () => setShowCalendarModal(false);
  const handleStartDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue);
  };
  const handleEndDateChange = (newValue: Dayjs | null) => {
    setEndDate(newValue);
  };

  // Obtener estado del negocio, conversaciones y estadísticas
  useEffect(() => {
    fetchBusinessStatus();
  }, []);

  const fetchConversations = () => {
    axios
      .get(`${config.backendURL}/get_conversations/${gojimx_businessId}`, {
        headers: { Authorization: `Bearer ${gojimx_token}` }
      })
      .then(response => {
        setConversations(response.data);
      })
      .catch(error => {
        console.error('Error fetching conversations:', error);
      });
  };

  const fetchStatistics = () => {
    axios
      .get(`${config.backendURL}/get_statistics/${gojimx_businessId}`, {
        headers: { Authorization: `Bearer ${gojimx_token}` }
      })
      .then(response => {
        setStatistics(response.data[0]);
      })
      .catch(error => {
        console.error('Error fetching statistics:', error);
      });
  };

  useEffect(() => {
    fetchStatistics();
    fetchConversations();
  }, []);

  // Filtrar por rango de fechas del modal de calendario
  const applyDateRangeFilter = () => {
    if (startDate && endDate) {
      setFormatLabelSegment('del ' + startDate.format('DD/MM/YYYY') + ' al ' + endDate.format('DD/MM/YYYY'));
      filterByDateRange(startDate, endDate);
    }
    handleCloseCalendarModal();
  };

  const filterByDateRange = (startDate: Dayjs, endDate: Dayjs) => {
    if (statistics) {
      const filtered = statistics.interactions.filter(interaction => {
        const interactionDate = moment(interaction.interaction_date);
        return interaction.interaction_date && interactionDate.isBetween(startDate.toDate(), endDate.toDate(), undefined, '[]');
      });
      setFilteredInteractions(filtered);
    }
    if (conversations) {
      const filteredConver = conversations.filter(conversation => {
        const conversationDate = moment(conversation.last_message_date);
        return conversation.last_message_date && conversationDate.isBetween(startDate.toDate(), endDate.toDate(), undefined, '[]');
      });
      setFilteredConversations(filteredConver);
    }
  };

  // Manejar cambio de pestaña para filtros
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    if (tab !== 'Range') {
      let start: moment.Moment;
      let end: moment.Moment;
      switch (tab) {
        case 'Today':
          setFormatLabelSegment('del día de hoy');
          start = moment().startOf('day');
          end = moment().endOf('day');
          break;
        case 'Week':
          setFormatLabelSegment('de la semana');
          start = moment().startOf('week');
          end = moment().endOf('week');
          break;
        case 'Month':
          setFormatLabelSegment('del mes');
          start = moment().startOf('month');
          end = moment().endOf('month');
          break;
        case 'Year':
          setFormatLabelSegment('del año');
          start = moment().startOf('year');
          end = moment().endOf('year');
          break;
        default:
          setFormatLabelSegment('del día de hoy');
          start = moment().startOf('day');
          end = moment();
      }
      const filtered = statistics?.interactions.filter(interaction => {
        const interactionDate = moment(interaction.interaction_date);
        return interaction.interaction_date && interactionDate.isBetween(start, end);
      }) || [];
      setFilteredInteractions(filtered);
      const filteredConver = conversations?.filter(conversation => {
        const conversationDate = moment(conversation.last_message_date);
        return conversation.last_message_date && conversationDate.isBetween(start, end);
      }) || [];
      setFilteredConversations(filteredConver);
    } else {
      handleOpenCalendarModal();
    }
  };

  // Animar contadores
  const animateValue = (start: number, end: number, duration: number, setter: (value: number) => void) => {
    let current = start;
    const range = end - start;
    const increment = end > start ? 1 : -1;
    const stepTime = Math.abs(Math.floor(duration / (range || 1)));
    const timer = setInterval(() => {
      current += increment;
      setter(current);
      if (current === end || (increment > 0 && current >= end) || (increment < 0 && current <= end)) {
        clearInterval(timer);
        setter(end);
      }
    }, stepTime);
    if (range === 0) {
      setter(end);
    }
  };

  const animateToken = (start: number, end: number, duration: number, setter: (value: number) => void) => {
    let current = start;
    const range = end - start;
    let increment = end > start ? 400 : -400;
    if (Math.abs(range) > 70000) {
      increment *= 4;
    }
    const stepTime = 0.1;
    const timer = setInterval(() => {
      current += increment;
      setter(current);
      if (current === end || (increment > 0 && current >= end) || (increment < 0 && current <= end)) {
        clearInterval(timer);
        setter(end);
      }
    }, stepTime);
    if (range === 0) {
      setter(end);
    }
  };

  // Actualizar contadores cuando cambien las interacciones o conversaciones filtradas
  useEffect(() => {
    if (filteredConversations) {
      const counts = filteredConversations.reduce((acc: { [key: string]: number }, conversation: Conversation) => {
        const category = conversation.category || 'Sin categoría';
        acc[category] = (acc[category] || 0) + 1;
        return acc;
      }, {});
      setCategoryCounts(counts);
    }
  }, [filteredConversations]);

  useEffect(() => {
    animateValue(interactionsCount, filteredInteractions.length, 1000, setInteractionsCount);
    let sumInputToken = 0;
    let sumOutputToken = 0;
    let sumTotalMessages = 0;
    filteredInteractions.forEach(interaction => {
      sumInputToken += interaction.inputToken || 0;
      sumOutputToken += interaction.outputToken || 0;
      sumTotalMessages += interaction.userMessages || 0;
    });
    animateToken(inputToken, sumInputToken, 10, setInputToken);
    animateToken(outputToken, sumOutputToken, 10, setOutputToken);
    animateValue(totalMessagesCount, sumTotalMessages, 1000, setTotalMessagesCount);
  }, [filteredInteractions]);

  useEffect(() => {
    animateValue(conversationsCount, filteredConversations.length, 1000, setConversationsCount);
  }, [filteredConversations]);

  // Crear gráfico cuando existan los datos de categorías
  useEffect(() => {
    if (chartContainer.current && Object.keys(categoryCounts).length > 0) {
      const data = {
        labels: Object.keys(categoryCounts),
        datasets: [
          {
            label: 'Categorías',
            data: Object.values(categoryCounts),
            backgroundColor: [
              'rgba(255, 99, 132, 0.6)',
              'rgba(54, 162, 235, 0.6)',
              'rgba(255, 206, 86, 0.6)',
              'rgba(75, 192, 192, 0.6)',
              'rgba(153, 102, 255, 0.6)',
              'rgba(255, 159, 64, 0.6)',
              // se pueden agregar más colores si es necesario
            ]
          }
        ]
      };

      const config: ChartConfiguration<'doughnut', number[], string> = {
        type: 'doughnut',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'right',
              labels: {
                padding: 20,
                font: { size: 16 },
                color: 'black'
              }
            }
          }
        }
      };

      const myChart = new Chart(chartContainer.current, config);
      return () => {
        myChart.destroy();
      };
    }
  }, [categoryCounts]);

  // Función de redirección para el Sidebar
  const redirect = (pRoute: string) => {
    const currentUrl = window.location.pathname;
    const targetRoute = '/' + pRoute;
    if (currentUrl !== targetRoute) {
      setTimeout(() => {
        navigate(targetRoute);
      }, 300);
    } else {
      handleClose();
    }
  };

  // Aplicar filtro por defecto en la primera carga
  useEffect(() => {
    if (!chartCreated && statistics && conversations) {
      setChartCreated(true);
      handleTabChange('Today');
    }
  }, [statistics, conversations, chartCreated]);

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      <div id="topbar">
        <TopBar handleShow={handleShow} />
      </div>
      <div className="home-layout">

        <Sidebar redirect={redirect} />
        <div className="main-content">

          {/* Fila que contiene la columna de filtros y la de contenido */}
          <div className="row px-4 py-4" style={{ height: 'calc(100vh - 200px)' }}>

            {/* Filtros a la izquierda */}
            <div className="col-md-3">
              <div className='row mb-3 animate__animated animate__fadeIn'>
                <h2 className="fw-bold text-dark">Estadísticas</h2>
              </div>
              <div className="shadow border rounded p-3 settings-sidebar">

                <div className="d-flex flex-column">
                  <div className="mb-3 text-center">
                    <div className="animate__animated animate__fadeIn mb-3 text-center d-flex justify-content-center">
                      <button
                        className="btn btn-light rounded-pill"
                        onClick={() => {
                          filteredInteractions
                            ? exportToExcelStatistics(filteredInteractions)
                            : alert('Sin información');
                        }}
                      >
                        Descargar reporte<i className="ms-1 lead bi bi-file-earmark-arrow-down"></i>
                      </button>
                    </div>

                    <hr className="border border-dark" />
                  </div>                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <button
                        className="btn text-start mb-2"
                        style={{
                          backgroundColor: activeTab === "Today" ? "#f0f0f0" : "transparent",
                          border: "none"
                        }}
                        onClick={() => handleTabChange('Today')}
                      >
                        Hoy
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="btn text-start mb-2"
                        style={{
                          backgroundColor: activeTab === "Week" ? "#f0f0f0" : "transparent",
                          border: "none"
                        }}
                        onClick={() => handleTabChange('Week')}
                      >
                        Semana
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="btn text-start mb-2"
                        style={{
                          backgroundColor: activeTab === "Month" ? "#f0f0f0" : "transparent",
                          border: "none"
                        }}
                        onClick={() => handleTabChange('Month')}
                      >
                        Mes
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="btn text-start mb-2"
                        style={{
                          backgroundColor: activeTab === "Year" ? "#f0f0f0" : "transparent",
                          border: "none"
                        }}
                        onClick={() => handleTabChange('Year')}
                      >
                        Año
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="btn text-start mb-2"
                        style={{
                          backgroundColor: activeTab === "Range" ? "#f0f0f0" : "transparent",
                          border: "none"
                        }}
                        onClick={() => handleTabChange('Range')}
                      >
                        Rango
                      </button>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
            {/* Contenido principal a la derecha */}
            <div className="col-md-9">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-8 col-sm-12">
                  <div className="row px-3 pb-5 animate__animated animate__fadeIn">


                    {/* Contadores */}
                    <div className="d-flex flex-column align-items-start">
                      <h1 className="fw-bold text-dark left-column-title">
                        Contadores{' '}
                        <i className="bi bi-question-circle" onClick={() => handleSupportChatOpen("¿Qué es un contador?")}></i>
                      </h1>
                      <p className="lead text-secondary">
                        Input tokens: {inputToken} - Output tokens: {outputToken}
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-4 text-center border-2 border-end">
                        <h1 className="display-1 fw-bold m-0 p-0">{conversationsCount}</h1>
                        <p className="lead m-0 p-0">Conversaciones</p>
                      </div>
                      <div className="col-4 text-center border-2 border-end">
                        <h1 className="display-1 fw-bold m-0 p-0">{interactionsCount}</h1>
                        <p className="lead m-0 p-0">Interacciones</p>
                      </div>
                      <div className="col-4 text-center">
                        <h1 className="display-1 fw-bold m-0 p-0">{totalMessagesCount}</h1>
                        <p className="lead m-0 p-0">Mensajes Enviados</p>
                      </div>
                    </div>
                    {/* Modal de Calendario para rango de fechas */}
                    <Modal size="lg" show={showCalendarModal} onHide={handleCloseCalendarModal} className="modal-dates">
                      <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">📅 Seleccione un rango de fechas</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="d-flex flex-column flex-lg-row flex-md-col flex-sm-col justify-content-between">
                          <div className="mb-3 mb-md-0 text-center">
                            <p className="lead m-0 p-0">Fecha inicial</p>
                            <DateCalendar value={startDate} onChange={handleStartDateChange} />
                          </div>
                          <div className="mb-3 mb-md-0 text-center">
                            <p className="lead m-0 p-0">Fecha final</p>
                            <DateCalendar value={endDate} onChange={handleEndDateChange} />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          <Button variant="primary" onClick={applyDateRangeFilter}>
                            Aplicar
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                    {/* Gráfico */}
                    <div className="d-block mt-5 mb-2 chart-container">
                      <h2 className="fw-bold text-dark mt-0 me-3">
                        Segmentación por categoría{' '}
                        <i className="bi bi-question-circle" onClick={() => handleSupportChatOpen("¿Qué es la segmentación por categoría?")}></i>
                      </h2>
                      <p className="lead m-0 p-0 text-secondary">Distribución de las conversaciones {formatLabelSegment}</p>
                    </div>
                    <div className="row d-flex px-3 justify-content-center animate__animated animate__fadeIn">
                      <div className="col-10 chart-container">
                        <canvas ref={chartContainer}></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* Fin de la fila principal */}
        </div>
      </div>
      {gojimx_payment_type === 'Premium' && (
        <>
          <div className="support-bubble animate__animated animate__fadeIn" onClick={() => handleSupportChatOpen('')}>
            💬
          </div>
          <SupportChatModal show={supportChatOpen} handleClose={handleSupportChatClose} predefinedQuestion={predefinedQuestion} />
        </>
      )}
      <Menu show={show} handleClose={handleClose} />
    </div>
  );
}

export default Statistics;