import React, { useEffect, useState } from 'react';
import '../../css/main.css';
import Menu from '../Menu/Menu';
import TopBar from '../../TopBar';
import axios from 'axios';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  TooltipItem,
} from 'chart.js';
import config from '../../config/config';
import SupportChatModal from '../Home/SupportChatModal';
import Sidebar from '../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Transaction {
  business_id: string;
  transaction_date: string;
  transaction_type: string;
  transaction_ammount: number;
  transaction_source: string;
  __v: number;
}

interface AverageAmountData {
  averageAmount: number;
  transaction_source: string;
}

function MyExpenses() {
  const [show, setShow] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('day');
  const [averageTransactionAmount, setAverageTransactionAmount] = useState<number>(0);
  const [averageTransactionAmountAverage, setAverageTransactionAmountAverage] = useState<number>(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [visibleTransactions, setVisibleTransactions] = useState<Transaction[]>([]);
  const [showMore, setShowMore] = useState(false);
  const [averageAmountBySourceData, setAverageAmountBySourceData] = useState<AverageAmountData[]>([]);
  const [currentPoints, setCurrentPoints] = useState<number>(0);

  const gojimx_payment_type = localStorage.getItem('gojimx_payment_type');
  const [predefinedQuestion, setPredefinedQuestion] = useState<string>();
  const navigate = useNavigate();
  const [supportChatOpen, setSupportChatOpen] = useState(false);

  const handleSupportChatOpen = (question: string) => {
    setPredefinedQuestion(question);
    setSupportChatOpen(true);
  };

  const redirect = (pRoute: string) => {
    const currentUrl = window.location.pathname;
    const targetRoute = '/' + pRoute;
    if (currentUrl !== targetRoute) {
      setTimeout(() => {
        navigate(targetRoute);
      }, 300);
    } else {
      handleClose();
    }
  };

  const handleSupportChatClose = () => setSupportChatOpen(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);
  };

  const animateValue = (start: number, end: number, duration: number, setter: (value: number) => void) => {
    let startTime: number | null = null;
    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const current = start + (end - start) * progress;
      setter(current);
      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        setter(end);
      }
    };
    requestAnimationFrame(animate);
  };

  const formatNumberWithCommas = (number: number) => {
    return new Intl.NumberFormat().format(number);
  };

  useEffect(() => {
    fetchTransactionsByPeriod(selectedPeriod);

    const fetchBusinessInfo = async () => {
      const gojimx_businessId = localStorage.getItem('gojimx_businessId');
      const gojimx_token = localStorage.getItem('gojimx_token');

      try {
        const response = await axios.get(
          `${config.backendURL}/get_business/${gojimx_businessId}`,
          {
            headers: {
              'Authorization': `Bearer ${gojimx_token}`
            }
          }
        );
        setCurrentPoints(Number(response.data[0].renata_points.toFixed(0)));
      } catch (error) {
        console.error('Error fetching business info:', error);
      }
    };

    fetchBusinessInfo();
    animateValue(0, Number(currentPoints.toFixed(0)), 1000, setCurrentPoints);
  }, [selectedPeriod]);

  useEffect(() => {
    const fetchAverageAmountBySourceData = async () => {
      const gojimx_businessId = localStorage.getItem('gojimx_businessId');
      const gojimx_token = localStorage.getItem('gojimx_token');

      try {
        const response = await axios.get<AverageAmountData[]>(
          `${config.backendURL}/averageAmountBySource/${gojimx_businessId}`,
          {
            headers: {
              'Authorization': `Bearer ${gojimx_token}`
            }
          }
        );
        setAverageAmountBySourceData(response.data);
      } catch (error) {
        console.error('Error fetching average amount data:', error);
      }
    };
    fetchAverageAmountBySourceData();
  }, []);

  const getPeriodDates = (period: string) => {
    const endDate = moment().endOf('day');
    let startDate;
    switch (period) {
      case 'day':
        startDate = moment().startOf('day');
        break;
      case 'week':
        startDate = moment().startOf('isoWeek');
        break;
      case 'month':
        startDate = moment().startOf('month');
        break;
      case 'year':
        startDate = moment().startOf('year');
        break;
      default:
        startDate = moment().startOf('year');
    }
    return { startDate: startDate.toISOString(), endDate: endDate.toISOString() };
  };

  const fetchTransactionsByPeriod = async (period: string) => {
    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    const gojimx_token = localStorage.getItem('gojimx_token');
    const { startDate, endDate } = getPeriodDates(period);

    try {
      const response = await axios.get<Transaction[]>(
        `${config.backendURL}/transactionsByDate/${gojimx_businessId}?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          'Authorization': `Bearer ${gojimx_token}`
        }
      }
      );
      const transactionsData = response.data.reverse();
      setTransactions(transactionsData);
      setVisibleTransactions(transactionsData.slice(0, 10));
      setShowMore(transactionsData.length > 10);

      const subtractTransactions = transactionsData.filter(transaction => transaction.transaction_type === 'subtract');
      const newAverage = calculateTransactionAmount(subtractTransactions, 'total');
      const newAmountAverage = calculateTransactionAmount(subtractTransactions, 'average');

      animateValue(averageTransactionAmount, newAverage, 500, setAverageTransactionAmount);
      animateValue(averageTransactionAmountAverage, newAmountAverage, 400, setAverageTransactionAmountAverage);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const calculateTransactionAmount = (transactions: Transaction[], type: 'total' | 'average') => {
    if (transactions.length === 0) return 0;
    const totalAmount = transactions.reduce((sum, transaction) => sum + transaction.transaction_ammount, 0);
    return type === 'average' ? totalAmount / transactions.length : totalAmount;
  };

  const handleLoadMore = () => {
    const newVisibleCount = visibleTransactions.length + 10;
    setVisibleTransactions(transactions.slice(0, newVisibleCount));
    setShowMore(newVisibleCount < transactions.length);
  };

  const sortedAverageAmountBySourceData = [...averageAmountBySourceData].sort((a, b) => b.averageAmount - a.averageAmount);

  const colors = [
    'rgba(54, 162, 235, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(255, 99, 132, 0.6)',
    'rgba(255, 205, 86, 0.6)',
    'rgba(255, 159, 64, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)',
    'rgba(201, 203, 207, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(255, 99, 132, 0.6)'
  ];

  const borderColors = [
    'rgba(54, 162, 235, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(255, 205, 86, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(201, 203, 207, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(255, 99, 132, 1)'
  ];

  const chartData = {
    labels: sortedAverageAmountBySourceData.map(item => item.transaction_source),
    datasets: [
      {
        label: 'Promedio por asistente',
        data: sortedAverageAmountBySourceData.map(item => item.averageAmount),
        backgroundColor: sortedAverageAmountBySourceData.map((_, index) => colors[index % colors.length]),
        borderColor: sortedAverageAmountBySourceData.map((_, index) => borderColors[index % borderColors.length]),
        borderWidth: 1,
      },
    ],
  };

  const chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<'bar'>) {
            return `${tooltipItem.label}: $${(tooltipItem.raw as number).toFixed(2)}`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Promedio por transacción',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Asistente',
          padding: { top: 10 }
        },
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 60,
        },
      },
    },
  };

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      <div id="topbar">
        <TopBar handleShow={handleShow} />
      </div>
      <div className="home-layout">
        <Sidebar redirect={redirect} />
        <div className="main-content">
          <div className="row p-4">
            <div className='row mb-3 animate__animated animate__fadeIn'>
              <h2 className="fw-bold text-dark">Consumo</h2>
            </div>
            {/* Left Filter Sidebar (matching the Statistics style) */}
            <div className="col-md-2">
              <div className="shadow border rounded p-3 settings-sidebar">
                <div className="d-flex flex-column">

                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <button
                        className="btn text-start mb-2"
                        style={{
                          backgroundColor: selectedPeriod === "day" ? "#f0f0f0" : "transparent",
                          border: "none"
                        }}
                        onClick={() => handlePeriodChange('day')}
                      >
                        Hoy
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="btn text-start mb-2"
                        style={{
                          backgroundColor: selectedPeriod === "week" ? "#f0f0f0" : "transparent",
                          border: "none"
                        }}
                        onClick={() => handlePeriodChange('week')}
                      >
                        Semana
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="btn text-start mb-2"
                        style={{
                          backgroundColor: selectedPeriod === "month" ? "#f0f0f0" : "transparent",
                          border: "none"
                        }}
                        onClick={() => handlePeriodChange('month')}
                      >
                        Mes
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="btn text-start mb-2"
                        style={{
                          backgroundColor: selectedPeriod === "year" ? "#f0f0f0" : "transparent",
                          border: "none"
                        }}
                        onClick={() => handlePeriodChange('year')}
                      >
                        Año
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="btn text-start mb-2"
                        style={{
                          backgroundColor: selectedPeriod === "total" ? "#f0f0f0" : "transparent",
                          border: "none"
                        }}
                        onClick={() => handlePeriodChange('total')}
                      >
                        Total
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Main Content */}
            <div className="col-md-10">
              <div className="row justify-content-center">
                <div className="col-lg-11 col-md-12 col-sm-12">

                  <div className="w-100">
                    <div className="d-flex align-items-baseline">
                      <h3 className="me-3" style={{ fontWeight: 'bold' }}>Contadores</h3>
                      <h6 className="text-secondary">
                        Costo promedio por interacción: {averageTransactionAmountAverage.toFixed(4)}
                        {/* <i className="bi bi-question-circle" onClick={() => handleSupportChatOpen("¿Qué es el promedio de interacción?")}></i> */}
                      </h6>
                    </div>
                    <div className="w-100 ">
                      <div className="border animate__animated animate__fadeIn"></div>
                    </div>
                    <div className="row  mt-3">
                      <div className="col-6">

                        <h4 style={{ fontWeight: 'bold' }}>Promedio por asistente</h4>
                        <div >
                          <div style={{ height: '400px' }}>
                            <Bar data={chartData} options={chartOptions} />
                          </div>                        </div>
                      </div>
                      <div className="col-6 ">
                        <h3 style={{ fontWeight: 'bold' }}>Transacciones</h3>
                        <h1 className="d-flex">
                          <i style={{ fontSize: '1.5rem' }} className="bi bi-x-diamond-fill text-primary me-3"></i>
                          <span style={{ fontSize: '1.5rem' }}>
                            <samp>{formatNumberWithCommas(averageTransactionAmount)}</samp>
                          </span>
                        </h1>
                        {visibleTransactions.map((transaction, index) => (
                          <h4 key={index} className="d-flex bg-light rounded px-4 p-2 mb-2">
                            <span className={`me-2 ${transaction.transaction_type === 'subtract' ? 'text-danger fw-bold' : 'text-success'}`}>
                              {transaction.transaction_type === 'subtract' ? '-' : '+'}
                            </span>
                            {transaction.transaction_ammount.toFixed(1)}
                            <span className="text-secondary ms-2">
                              <i>{new Date(transaction.transaction_date).toLocaleString('es-MX', { dateStyle: 'short', timeStyle: 'short' })}</i>
                            </span>
                          </h4>
                        ))}
                        {showMore && (
                          <h4 className="d-flex bg-light rounded p-2 mb-2 btn" onClick={handleLoadMore}>
                            <span className="text-secondary ms-2">
                              <i>Cargar más</i>
                            </span>
                          </h4>
                        )}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menu show={show} handleClose={handleClose} />
      {gojimx_payment_type === 'Premium' && (
        <>
          <div className="support-bubble animate__animated animate__fadeIn" onClick={() => handleSupportChatOpen("")}>
            💬
          </div>
          <SupportChatModal show={supportChatOpen} handleClose={handleSupportChatClose} predefinedQuestion={predefinedQuestion} />
        </>
      )}
    </div>
  );
}

export default MyExpenses;