import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { formatPhoneNumber } from '../../functions/functions';
import config from '../../config/config';
import loadingGif from '../../img/loading.gif';
import renata_img from '../../img/renata.png'; // Import Renata's image
import user_img from '../../img/user.png';
import ConfirmationModal from '../Modals/ConfirmationModal';
import axios from 'axios';
import Logo from '../../img/renata-settings.png';

interface Conversation {
    category: string;
    id: string;
    threadId: string;
    business_id: string;
    summary: string;
    user_name: string;
    user_phone: string;
    renata_sleep: boolean;
    active_conversation: boolean;
    last_message_date: string;
}

interface Message {
    id: string;
    object: string;
    created_at: number;
    assistant_id: string | null;
    thread_id: string;
    run_id: string | null;
    role: string;
    content: {
        type: string;
        text: {
            value: string;
            annotations: any[];
        };
    }[];
    file_ids: any[];
    metadata: Record<string, unknown>;
}

export interface Assistant {
    assistant_id: string;
    name: string;
    description: string;
    intelligenceLevel: string;
    responseLength: string;
    workingHoursStart: number;
    workingHoursEnd: number;
    responseSpeed: string;
    instructions: string;
    active: boolean;
    active_skills: any[];
    assistant_knowledge_base: string;
    whitelist: any[];
    _id: string;
    voice?: string;
}

export interface Channel {
    channel_name: string;
    active: boolean;
    username: string;
    baileys_port: string;
    baileys_status: boolean;
    main_number: boolean;
    channel_type: string;
    _id: string;
    assistant_id: string;
}

interface RightColumnProps {
    selectedConversationPreview: Conversation | undefined;
    categoryIcons: { [key: string]: string | undefined };
    handleShowModalDetails: () => void;
    fetchConversations: () => void;
    assistants: Assistant[];
    channels: Channel[];
}

const RightColumn: React.FC<RightColumnProps> = ({
    selectedConversationPreview,
    categoryIcons,
    handleShowModalDetails,
    fetchConversations,
    assistants,
    channels,
}) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const gojimx_token = localStorage.getItem('gojimx_token');
    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    const [fetchingMessages, setFetchingMessages] = useState(false);
    const conversationBoxRef = useRef<HTMLDivElement>(null);
    const [renata_sleep, setRenataSleep] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [confirmModalType, setConfirmModalType] = useState('✅');
    const [newMessage, setNewMessage] = useState("");

    // State for the reset confirmation modal
    const [showResetModal, setShowResetModal] = useState(false);

    // New state for saving the second number extracted from the conversation id
    const [secondNumber, setSecondNumber] = useState<string | null>(null);

    // Extract the second number every time selectedConversationPreview changes
    useEffect(() => {
        if (selectedConversationPreview && selectedConversationPreview.id) {
            const parts = selectedConversationPreview.id.split('@s.whatsapp.net');
            if (parts.length > 1) {
                setSecondNumber(parts[1]);
            } else {
                setSecondNumber(null);
            }
        } else {
            setSecondNumber(null);
        }
    }, [selectedConversationPreview]);

    // Find the channel whose username matches the second number
    const matchingChannel = channels.find(channel => channel.username === secondNumber);

    // Now, find the assistant whose assistant_id matches the matchingChannel's assistant_id
    const matchingAssistant = assistants.find(
        assistant => assistant.assistant_id === matchingChannel?.assistant_id
    );

    async function handleRenataSleepChange(e: { target: { checked: any } }) {
        const newRenataSleepStatus = !e.target.checked;
        if (selectedConversationPreview) {
            const updatedConversation = { ...selectedConversationPreview, renata_sleep: newRenataSleepStatus };
            const url = `${config.backendURL}/updateRenataSleep`;
            const requestBody = {
                threadId: updatedConversation.threadId,
                renata_sleep: newRenataSleepStatus,
            };

            try {
                await axios.post(url, requestBody, {
                    headers: {
                        Authorization: `Bearer ${gojimx_token}`,
                        'Content-Type': 'application/json',
                    },
                });
                setConfirmModalType('✅');
                setModalMessage(`Renata ha sido ${newRenataSleepStatus ? 'apagada' : 'encendida'} en esta conversación.`);
                setIsModalOpen(true);
                selectedConversationPreview.renata_sleep = newRenataSleepStatus;
            } catch (error) {
                console.error('Error updating Renata sleep status:', error);
                setConfirmModalType('❌');
                setModalMessage('Failed to update Renata sleep status. Please try again.');
                setIsModalOpen(true);
                window.location.reload();
            }
        }
    }

    // New function to reset (clear) the conversation
    async function handleResetConversation() {
        if (selectedConversationPreview) {
            try {
                await axios.post(
                    `${config.backendURL}/reset-conversation`,
                    { id: selectedConversationPreview.id },
                    {
                        headers: {
                            Authorization: `Bearer ${gojimx_token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                setConfirmModalType('✅');
                setModalMessage('La conversación ha sido reiniciada.');
                setIsModalOpen(true);
                fetchConversations();
            } catch (error) {
                console.error('Error reiniciando la conversación:', error);
                setConfirmModalType('❌');
                setModalMessage('Error al reiniciar la conversación. Por favor, intente nuevamente.');
                setIsModalOpen(true);
            }
        }
        setShowResetModal(false);
    }

    function convertirAFecha(fechaEnSegundos: number) {
        const fechaEnMilisegundos = fechaEnSegundos * 1000;
        const fecha = new Date(fechaEnMilisegundos);
        return fecha.toLocaleString();
    }

    // Helper function to remove brackets from message text
    function eliminarCorchetes(text: string) {
        if (text.startsWith('[RENATA MESSAGE]')) {
            return text;
        }
        const regex = /\[.*?\]/g;
        return text.replace(regex, '');
    }

    const scrollToBottom = () => {
        if (conversationBoxRef.current) {
            conversationBoxRef.current.scrollTo({
                top: conversationBoxRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    // Updated fetchMessages function
    const fetchMessages = async (firstTime: boolean) => {
        if (!selectedConversationPreview) return;
        if (firstTime) setFetchingMessages(true);
        const url = `${config.backendURL}/get_messages/${selectedConversationPreview?.threadId}`;
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${gojimx_token}`,
            },
        });
        if (!response.ok) {
            setFetchingMessages(false);
            console.error("Network response was not ok");
            return;
        }
        const data = await response.json();
        const fetchedMessages = data.data.reverse();

        if (firstTime) {
            // For the first fetch, just set the messages
            setMessages(fetchedMessages);
            setTimeout(scrollToBottom, 200);
            if (data.renata_sleep) {
                setRenataSleep(data.renata_sleep);
            }
        } else {
            // Append only new messages
            setMessages((prevMessages: Message[]) => {
                const existingMessageIds = new Set(prevMessages.map((msg: Message) => msg.id));
                const newMessages = fetchedMessages.filter((msg: Message) => !existingMessageIds.has(msg.id));
                return newMessages.length > 0 ? [...prevMessages, ...newMessages] : prevMessages;
            });
        }
        setFetchingMessages(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (selectedConversationPreview?.threadId) {
                await fetchMessages(true);
            }
        };
        fetchData();

        // Poll every 3 seconds
        const intervalId = setInterval(() => {
            if (selectedConversationPreview?.threadId) {
                fetchMessages(false);
            }
        }, 3000);

        return () => clearInterval(intervalId);
    }, [selectedConversationPreview]);

    // Add the handleSendMessage function
    const handleSendMessage = async () => {
        if (!selectedConversationPreview) return;

        const url = `${config.backendURL}/addMessage`;
        const requestBody = {
            param_threadId: selectedConversationPreview.threadId,
            business_id: gojimx_businessId,
            whatsAppNumber: selectedConversationPreview.user_phone,
            messageText: newMessage.trim(),
            channel: selectedConversationPreview.id,
        };

        setNewMessage('');

        try {
            const response = await axios.post(url, requestBody, {
                headers: {
                    Authorization: `Bearer ${gojimx_token}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log('Message sent successfully:', response.data);
            setConfirmModalType('✅');
            setModalMessage('Mensaje enviado correctamente.');
            setIsModalOpen(true);

            fetchMessages(false);
            scrollToBottom();
        } catch (error) {
            setConfirmModalType('❌');
            setModalMessage('Error al mandar el mensaje. Verifique en la configuración que el canal de WhatsApp se encuentre conectado correctamente.');
            setIsModalOpen(true);
            console.error('Error sending message:', error);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const inputValue = (e.target as HTMLInputElement).value;
        const regex = /[a-zA-Z]/;
        if (e.key === 'Enter' && regex.test(inputValue)) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    return (
        <div className='col-md-8 shad' style={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column', paddingTop: '20px', paddingRight: '15px' }}>
            {selectedConversationPreview && (
                <>
                    {selectedConversationPreview.threadId && (
                        <div className="bg-gray p-3 rounded shadow-sm">
                            {/* Primera fila: Nombre del usuario y controles a la derecha */}
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h5 className="fw-bold text-dark mb-1 text-truncate">
                                        {selectedConversationPreview.user_name}
                                    </h5>
                                </div>
                                <div className="d-flex align-items-center">
                                    <a
                                        className="text-danger mx-2 d-flex align-items-center"
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "none",
                                            textDecorationLine: "underline",
                                        }}
                                        onClick={() => {
                                            // Open the reset confirmation modal
                                            setShowResetModal(true);
                                        }}
                                    >
                                        <i className="bi bi-eraser me-1"></i>
                                        Limpiar conversación
                                    </a>
                                    <button
                                        className="btn me-2"
                                        onClick={() => {
                                            const link =
                                                config.frontURL +
                                                "/messages?thread=" +
                                                String(selectedConversationPreview?.threadId) +
                                                "&name=" +
                                                selectedConversationPreview?.user_name +
                                                "&id=" +
                                                selectedConversationPreview?.id +
                                                "&phone=" +
                                                selectedConversationPreview?.user_phone +
                                                "&summary=" +
                                                selectedConversationPreview?.summary +
                                                "&categoryName=" +
                                                selectedConversationPreview?.category +
                                                (categoryIcons && selectedConversationPreview?.category
                                                    ? "&icon=" +
                                                      categoryIcons[selectedConversationPreview?.category]
                                                    : "");

                                            navigator.clipboard
                                                .writeText(link)
                                                .then(() => {
                                                    setModalMessage("Se copió el link para compartir la conversación");
                                                    setIsModalOpen(true);
                                                })
                                                .catch((err) => {
                                                    setModalMessage("No se pudo copiar el link para compartir la conversación");
                                                    setIsModalOpen(true);
                                                    console.error("Failed to copy: ", err);
                                                });
                                        }}
                                    >
                                        <i className="bi bi-share"></i>
                                    </button>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={
                                                selectedConversationPreview?.renata_sleep === false ||
                                                selectedConversationPreview?.renata_sleep === undefined
                                            }
                                            onChange={handleRenataSleepChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Segunda fila: Resumen */}
                            <p>{selectedConversationPreview.summary}</p>
                            {/* Tercera fila: Categoría y asistente */}
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="badge bg-warning text-dark d-inline-flex align-items-center">
                                    <h4 className="mb-0">
                                        {categoryIcons[selectedConversationPreview.category]}
                                    </h4>
                                    <h6 className="mx-2 mb-0 fw-bold text-dark">
                                        {selectedConversationPreview.category}
                                    </h6>
                                </div>
                                <div className="text-dark d-inline-flex align-items-center">
                                    <img src={Logo} alt="Assistant Logo" style={{ width: "40px" }} />
                                    <h6 className="mx-2 mb-0 fw-bold text-dark">
                                        {matchingAssistant?.name}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    )}

                    <div
                        className='bg-gray p-3 rounded mt-4 shadow-sm'
                        style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
                    >
                        <div className='d-flex align-items-center mx-3 justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <h5 className='fw-bold text-dark mb-0 me-3'>WhatsApp</h5>
                                <h5 className='fw-bolder mb-0 text-secondary'>{formatPhoneNumber(selectedConversationPreview.user_phone)}</h5>
                            </div>
                        </div>

                        {fetchingMessages ? (
                            <div className='d-flex justify-content-center mt-5'>
                                <img src={loadingGif} alt="Cargando..." style={{ width: '30px' }} />
                            </div>
                        ) : selectedConversationPreview.threadId && selectedConversationPreview.active_conversation !== false ? (
                            <>
                                <div
                                    className='rounded conversation-box-my-conversations m-3'
                                    style={{ flexGrow: 1, overflow: 'hidden' }}
                                >
                                    <div
                                        className='conversation-scroll-my-conversations bg-white'
                                        ref={conversationBoxRef}
                                        style={{ height: '100%', overflowY: 'auto' }}
                                    >
                                        <div className="fade-top-preview"></div>
                                        {messages.map((message) => {
                                            const messageText = message.content[0].text.value;
                                            const regex = /"mensaje_original_del_usuario":\s*"([^"]*)"/;
                                            const match = messageText.match(regex);
                                            const displayedMessage = match ? match[1] : messageText;

                                            const messageClass =
                                                messageText.startsWith('[RENATA MESSAGE]') || message.role !== 'user'
                                                    ? 'message-item-2'
                                                    : 'message-item-1';
                                            const isRenataMessage = message.role !== 'user' || messageText.startsWith('[RENATA MESSAGE]');

                                            return (
                                                <div key={message.id} className="row px-3 animate__animated animate__fadeIn">
                                                    <div className={`d-flex justify-content-${isRenataMessage ? 'end' : 'start'}`}>
                                                        {!isRenataMessage ? <img src={user_img} className='user_image rounded mx-2' alt="User" /> : null}
                                                        <p className={`${messageClass} mb-0 p-3`}>{eliminarCorchetes(displayedMessage)}</p>
                                                        {isRenataMessage ? <img src={renata_img} className='renata_image rounded mx-2' alt="Renata" /> : null}
                                                    </div>
                                                    <div className={`d-flex justify-content-${isRenataMessage ? 'end' : 'start'}`}>
                                                        <p className={`text-secondary pt-0 mt-0 mb-3 ${isRenataMessage ? 'me-date' : 'me-date2'}`}>
                                                            {(message?.metadata?.audio ? '🎤(Mensaje de voz) ' : '') + convertirAFecha(Number(message.created_at))}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className='mt-2 d-flex px-3 mb-2'>
                                    <div className='row w-100 border border-2 rounded'>
                                        <div className="col-10 px-1">
                                            <input
                                                type="text"
                                                className="form-control form-control-lg form-control-custom py-2"
                                                placeholder="Escribe tu mensaje..."
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>
                                        <div className="col-2 ps-0 pe-2 py-2">
                                            <button
                                                className="btn btn-dark w-100"
                                                disabled={!newMessage.trim()}
                                                onClick={handleSendMessage}
                                            >
                                                <i className="bi bi-send-fill"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className='d-flex justify-content-center mt-5'>
                                <h5 className='fw-bold text-dark mb-0 me-3'>Conversación reiniciada</h5>
                            </div>
                        )}
                    </div>
                </>
            )}

            {/* Confirmation Modal for operation results */}
            <ConfirmationModal
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                text={modalMessage}
                type={confirmModalType}
                titleText="Resultado de la Operación"
            />

            {/* Reset Conversation Confirmation Modal */}
            <Modal show={showResetModal} onHide={() => setShowResetModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>Confirmar reinicio de conversación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Estás seguro de que deseas reiniciar esta conversación? Esta acción no se puede deshacer.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => setShowResetModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleResetConversation}>
                        Reiniciar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RightColumn;