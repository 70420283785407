// src/components/Funnels/EditFunnelModal.tsx
import React from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { Assistant } from '../Conversations/RightColumn';

export interface EditFunnelModalProps {
  show: boolean;
  onClose: () => void;
  onSave: (updatedFunnelData: {
    funnel_name: string;
    description: string;
    assistants: string[];
    categories: string[];
    icon?: string;
    schema_version?: string;
  }) => void | Promise<void>;
  onDelete: () => void | Promise<void>;
  isSaving?: boolean;
  isDeleting?: boolean;
  // Funnel data to prepopulate the form
  funnel: {
    _id?: string;
    funnel_name: string;
    description: string;
    assistants: string[];
    categories: string[];
    icon?: string;
    schema_version?: string;
  } | null;

  // Available assistants and category icons
  assistants: Assistant[];
  categoryIcons: { [key: string]: any };

  // These state props are passed in so the modal uses the same style as NewFunnelModal
  selectedAssistants: string[];
  setSelectedAssistants: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCategories: string[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
  selectedIcon: string;
  setSelectedIcon: React.Dispatch<React.SetStateAction<string>>;
  funnelName: string;
  setFunnelName: React.Dispatch<React.SetStateAction<string>>;
  funnelDescription: string;
  setFunnelDescription: React.Dispatch<React.SetStateAction<string>>;
}

const EditFunnelModal: React.FC<EditFunnelModalProps> = ({
  show,
  onClose,
  onSave,
  onDelete,
  isSaving = false,
  isDeleting = false,
  funnel,
  assistants,
  categoryIcons,
  selectedAssistants,
  setSelectedAssistants,
  selectedCategories,
  setSelectedCategories,
  selectedIcon,
  setSelectedIcon,
  funnelName,
  setFunnelName,
  funnelDescription,
  setFunnelDescription,
}) => {
  // Toggling selection for an assistant
  const toggleAssistantSelection = (assistantId: string) => {
    setSelectedAssistants(prev =>
      prev.includes(assistantId)
        ? prev.filter(id => id !== assistantId)
        : [...prev, assistantId]
    );
  };

  // Toggling selection for a category
  const toggleCategorySelection = (category: string) => {
    setSelectedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const iconOptions = [
    "🔴",
    "🟡",
    "🟢",
    "🔵",
    "🚨",
    "💯",
    "👍",
    "👎",
    "🔥",
    "😄",
    "😞",
    "😐",
    "😠",
    "🌟",
    "🚀",
    "🎉",
    "💡",
    "📈",
    "📉",
    "🔒",
    "🔓",
    "🛠️",
    "⚙️",
    "💬",
    "📧",
    "📞",
    "🏆",
    "🎯",
    "📅",
    "📝"
  ];



  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Editar embudo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Icon Selector */}
          <Form.Group className="mb-3">
            <Form.Label><strong>Ícono:</strong></Form.Label>
            <Form.Select
              value={selectedIcon}
              onChange={(e) => setSelectedIcon(e.target.value)}
            >
              <option value="">Selecciona un ícono...</option>
              {iconOptions.map((icon) => (
                <option key={icon} value={icon}>
                  {icon}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {/* Name */}
          <Form.Group className="mb-3">
            <Form.Label><strong>Nombre:</strong></Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre del embudo"
              value={funnelName}
              onChange={(e) => setFunnelName(e.target.value)}
            />
          </Form.Group>

          {/* Description */}
          <Form.Group className="mb-3">
            <Form.Label><strong>Descripción:</strong></Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="¿De qué trata tu embudo?"
              value={funnelDescription}
              onChange={(e) => setFunnelDescription(e.target.value)}
            />
          </Form.Group>

          {/* Assistants */}
          <Form.Group className="mb-3">
            <Form.Label><strong>Asistentes:</strong></Form.Label>
            <div className="d-flex flex-wrap">
              {assistants.map((assistant) => (
                <Button
                  key={assistant._id}
                  variant={
                    selectedAssistants.includes(assistant.assistant_id)
                      ? 'primary'
                      : 'outline-primary'
                  }
                  className="me-2 mb-2"
                  onClick={() => toggleAssistantSelection(assistant.assistant_id)}
                >
                  {assistant.name}
                </Button>
              ))}
            </div>
          </Form.Group>

          {/* Categories */}
          <Form.Group className="mb-3">
            <Form.Label><strong>Categorías:</strong></Form.Label>
            <div className="d-flex flex-wrap">
              {Object.keys(categoryIcons).map((category) => (
                <Button
                  key={category}
                  variant={
                    selectedCategories.includes(category)
                      ? 'success'
                      : 'outline-success'
                  }
                  className="me-2 mb-2"
                  onClick={() => toggleCategorySelection(category)}
                >
                  {category}
                </Button>
              ))}
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button 
          variant="danger" 
          onClick={onDelete} 
          disabled={isDeleting || isSaving}
        >
          {isDeleting ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Eliminando...
            </>
          ) : (
            "Eliminar"
          )}
        </Button>
        <div>
          <Button 
            variant="secondary" 
            onClick={onClose} 
            className="me-2" 
            disabled={isDeleting || isSaving}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onSave({
                funnel_name: funnelName,
                description: funnelDescription,
                assistants: selectedAssistants,
                categories: selectedCategories,
                icon: selectedIcon,
                schema_version: funnel?.schema_version || '1.0',
              });
            }}
            disabled={isDeleting || isSaving}
          >
            {isSaving ? (
              <>
                <Spinner animation="border" size="sm" className="me-2" />
                Guardando...
              </>
            ) : (
              "Guardar cambios"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditFunnelModal;